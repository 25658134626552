import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import ButtonsSubOutlined from "./buttons/ButtonsSubOutlined";

type NavigationMainProps = {
  property: "main" | "depth";
  elType?: "link" | "button";
  to?: string;
  title?: string;
  onClick?: () => void;
  bgColor?: "white" | "gray";
  isCrossword?: boolean;
  handleSignout?: () => void;
};

export default function NavigationMain({
  property,
  elType,
  to,
  title,
  onClick,
  bgColor,
  isCrossword,
  handleSignout
}: NavigationMainProps) {
  if (property === "main") {
    return (
      <header
        className={`fixed top-0 z-50 flex justify-center items-center w-full h-14 lg:h-16 responsive-padding ${
          isCrossword
            ? "bg-transparent border-none"
            : "bg-white border-b border-solid border-gray20"
        }`}
      >
        <div className="flex justify-between items-center w-full responsive-max-width">
          <Link to="/main">
            {/** @todo isCrossword가 true일 때 로고 추가 */}
            <ReactSVG
              src={`${process.env.PUBLIC_URL}/images/logo/${
                !isCrossword ? "header_logo" : "header_logo_white"
              }.svg`}
            />
          </Link>
          <div className="flex font-semibold text-[#4894fe] whitespace-nowrap">
            {/** @todo 필요 시 ButtonsSubOutlined 컴포넌트로 교체 */}
            {/** @todo 매니저/어드민 버튼 UI 확정 시 구현 */}
            {/* {profile.role !== "ROLE_USER" && (
                  <>
                    <NavLink
                      to="/main/admin/managers"
                      defaultIcon="icon_profile"
                      clickedIcon="icon_profile_white"
                      children="어드민"
                    />
                    <NavLink
                      to="/main/manager/customer"
                      defaultIcon="icon_profile"
                      clickedIcon="icon_profile_white"
                      children="매니저"
                    />
                  </>
                )} */}
            <ButtonsSubOutlined
              type="link"
              mode="enable/icon"
              iconName="ico_profile_gray"
              text="사용자정보"
              to="/main/myPage"
              className="h-10 py-2 px-3"
            />
            {/* 설정 삭제 : 사용자 정보 내부 또는 추가 기획 필요.
                <NavLink
                  to="/main/settings"
                  defaultIcon="icon_setting"
                  clickedIcon="icon_setting_white"
                  children="설정"
                /> */}
          </div>
        </div>
      </header>
    );
  } else if (property === "depth") {
    return (
      <header
        className={`fixed top-0 flex justify-center items-center w-full h-14 lg:h-16 responsive-padding z-40 ${
          bgColor === "white" ? "bg-white" : "bg-gray10"
        }`}
      >
        <div className="relative flex w-full h-14 justify-center items-center py-4 max-w-[1200px]">
          {elType === "link" ? (
            <Link
              className="absolute left-0"
              to={to as string}
            >
              <ReactSVG
                className="w-6 h-6"
                src={`${process.env.PUBLIC_URL}/images/icons_update/ico_left.svg`}
              />
            </Link>
          ) : (
            <button
              className="absolute left-0"
              onClick={onClick}
            >
              <ReactSVG
                className="w-6 h-6"
                src={`${process.env.PUBLIC_URL}/images/icons_update/ico_left.svg`}
              />
            </button>
          )}

          <p className="w-full text-center font-bold lg:text-2xl">{title}</p>
          {window.location.pathname === "/main/myPage" && (
            <ButtonsSubOutlined
              className="absolute right-0 h-10 py-2 px-3"
              type="button"
              mode="enable"
              text="로그아웃"
              onClick={handleSignout}
            />
          )}
        </div>
        <hr className="absolute bottom-0 border-gray20 w-screen" />
      </header>
    );
  }

  // property가 "main" 또는 "depth"가 아닐 경우 명시적으로 null 반환
  return null;
}
