import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropResult } from "react-beautiful-dnd";

import Utils from "@libs/utils";
import type {
  TrainingItemType,
  AudioURLType,
  TrainingResponseType,
  TrainingResponseCountType
} from "@libs/common.types";

// import useAppData from "@hooks/useAppData";
import useNavigateToNext from "@hooks/useNavigateToNext";
import useNavigateToBack from "@hooks/useNavigateToBack";

import useAudioUrl from "@hooks/useAudioUrl";

import { taskCompletionState } from "@states/progress";

import QuizTopicHeader from "@components/tests/QuizTopicHeader";
import SentenceOrderingQuizContent from "@components/tests/quizContents/SentenceOrderingQuizContent";
import NavigationMain from "@components/common/NavigationMain";

export default function SentenceOrdering() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { itemName, day } = useParams<{ itemName: string; day: string }>();
  // legacy code
  // const { getPublicSoundBucket, getSignedSoundBucket } = useAppData();
  // new fetch audio url
  const {
    loadAudioErr,
    setLoadAudioErr,
    isLoadingAudio,
    setLoadingAudio,
    loadAudio,
    fetchAudioUrl
  } = useAudioUrl();

  const defaultMessage = "먼저 문장을 하나씩 모두 듣고 순서를 완성해보세요!";
  const questionMessage = "문장 박스를 움직여 순서를 맞추어 보세요!";

  const [trainingGroupId, setTrainingGroupId] = useState<string | null>();
  const [trainingItems, setTrainingItems] = useState<TrainingItemType[]>();
  const [trainingItemProgress, setTrainingItemProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [audioUrls, setAudioUrls] = useState<AudioURLType[]>([]);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const audioInstance = useState(new Audio())[0]; // 하나의 오디오 객체 생성
  const [isPlay, setIsPlay] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [isStartSolvingQuiz, setIsStartSolvingQuiz] = useState(false);
  const [message, setMessage] = useState(defaultMessage);
  const [playingIndex, setPlayingIndex] = useState<number | null>(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [correctOrder, setCorrectOrder] = useState<string[]>([]); // 정답 순서를 저장
  const [results, setResults] = useState<Result[]>([]); // 정답 결과 배열

  const [isSolved, setIsSolved] = useState(false); // 퀴즈가 해결되었는지 상태 관리
  const [isCheckAnswer, setIsCheckAnswer] = useState(false);
  // 퀴즈 풀기 버튼을 클릭했을 때의 로직
  // const [responses, setResponses] = useState<ITrainingResponseRecord>({});
  const [currentResponse, setCurrentResponse] =
    useState<TrainingResponseType | null>(null);
  const [responseCounts, setResponseCounts] =
    useState<TrainingResponseCountType>({
      correct: 0,
      incorrect: 0
    });

  // console.log("trainingGroupId", trainingGroupId);
  // console.log("trainingItems", trainingItems);
  // console.log("trainingItemProgress", trainingItemProgress);

  useEffect(() => {
    // escaping direct url loading -> temporary solution
    // TODO: show error massage and redirect to main page
    // TODO: or reload data gracefully
    if (!state) navigate("/main");
  }, [state]);

  useEffect(() => {
    console.log("audioUrls", audioUrls);
  }, [audioUrls]);

  // 문제 데이터 불러오기
  useEffect(() => {
    if (!state) return;
    const currentItemIndex = state.currentIndex;
    const currentItem = state.items[currentItemIndex];
    const item = Utils.findTrainingItemsById(state.type, currentItem.id);

    if (item) {
      if (!(item.items && item.items.length > 0)) {
        alert("문제 데이터를 준비중입니다.");
        navigateToBack();
        return;
      }
      const shuffledItems = Utils.shuffleArray(item.items); // 셔플된 items
      setTrainingItems(shuffledItems);
      setCorrectOrder(item.items.map((item) => item.index.toString())); // 정답 순서를 설정, 문자열 배열로 변환

      // TrainingItemProgress
      // 0: 준비 상태, 1: 문제풀이 시작번호, trainingItems.length : 문제풀이 완료
      setTrainingItemProgress(1);
      setTrainingGroupId(item.id);
    }
  }, [state && state.currentIndex]);
  //

  // 문제 번호 변경 시 응답 초기화
  useEffect(() => {
    if (trainingItems && trainingItems.length > 0) {
      setIsCompleted(trainingItemProgress > trainingItems.length);
      setIsStartSolvingQuiz(trainingItemProgress > trainingItems.length);
      setCurrentResponse(null);
    }
  }, [trainingItemProgress]);

  useEffect(() => {
    if (!state) return;
    if (!(trainingItems && trainingItems.length > 0)) return;

    // TODO: 문제 로직마다 다르게 설정해야 할 수 있음.(ex: 짧은 이야기)
    const promisses = trainingItems.map(async (item) => {
      const path = Utils.generateAudioBucketPath(state.type, item.contextCode);
      if (path && path !== "") {
        // const _url = await getSignedSoundBucket(path);
        const _url = await fetchAudioUrl(path);
        return { index: item.index, path: path, url: _url };
      } else {
        throw new Error("Error Audio Path");
      }
    });

    // TODO: 순서보장 확인
    // 만약 예외 확인되면 index로 sort
    Promise.all(promisses)
      .then((results) => results.map((res) => res as AudioURLType))
      .then((urls) => {
        const newValues = urls.filter((url) => url !== undefined);
        setAudioUrls(newValues);
        return newValues;
      })
      .then((newValues) => {
        setAudio(new Audio(newValues[0].url));
        setLoadingAudio(false);
      })
      .catch((err) => {
        console.error(`❌ Failed fetch audio url (SentenceOrdering)`);
        console.error(err);
      });
  }, [trainingGroupId]);

  useEffect(() => {
    if (isStartSolvingQuiz) {
      setMessage(questionMessage);
    } else {
      handleReset();
      setMessage(defaultMessage);
      setTrainingItemProgress(1);
    }
  }, [isStartSolvingQuiz]);

  // n번 문장 오디오 재생 로직 (검사 시작 전 화면)
  const orderedAudioUrls = useMemo(
    () => [...audioUrls].sort((a, b) => a.index - b.index),
    [audioUrls]
  );

  // // 로딩 상태를 관리하는 useEffect
  // useEffect(() => {
  //   // 새로운 문제로 이동할 때 로딩 시작
  //   setIsLoading(true);
  // }, [itemName]); // currentIndex 또는 itemName이 변경될 때 실행

  // useEffect(() => {
  //   if (audioUrls.length > 0) {
  //     setIsLoading(false); // 오디오가 준비되면 로딩 종료
  //   }
  // }, [audioUrls]);

  const handlePlayAudio = (index: number) => {
    if (playingIndex === index) {
      // 현재 재생 중인 오디오를 다시 클릭했을 때 일시정지
      audioInstance.pause();
      setIsPlay(false);
      setPlayingIndex(null);
      return;
    }

    if (audioInstance.src !== orderedAudioUrls[index].url) {
      audioInstance.src = orderedAudioUrls[index].url; // 새로운 URL 설정
    }

    audioInstance.pause(); // 기존 오디오 정지
    audioInstance.currentTime = 0; // 재생 위치 초기화

    audioInstance
      .play()
      .then(() => {
        setPlayingIndex(index);
        setIsPlay(true);
      })
      .catch((error) => {
        console.error("Audio playback failed:", error);
      });

    audioInstance.onended = () => {
      setIsPlay(false);
      setPlayingIndex(null);
    };
  };

  // n번 문장 오디오 재생 로직 (검사 진행 화면)
  const handlePlayAudioForItem = (itemIndex: number) => {
    if (playingIndex === itemIndex) {
      // 현재 재생 중인 오디오를 다시 클릭했을 때 일시정지
      audioInstance.pause();
      setIsPlay(false);
      setPlayingIndex(null);
      return;
    }

    const audioUrl = audioUrls.find((audio) => audio.index === itemIndex)?.url;
    if (!audioUrl) return;

    if (audioInstance.src !== audioUrl) {
      audioInstance.src = audioUrl; // 새로운 URL 설정
    }

    audioInstance.pause();
    audioInstance.currentTime = 0;

    audioInstance
      .play()
      .then(() => {
        setPlayingIndex(itemIndex);
        setIsPlay(true);
      })
      .catch((error) => {
        console.error("Audio playback failed:", error);
      });

    audioInstance.onended = () => {
      setIsPlay(false);
      setPlayingIndex(null);
    };
  };
  // audioInstance 객체 초기화
  useEffect(() => {
    return () => {
      audioInstance.pause();
      audioInstance.onended = null;
    };
  }, []);

  // isStartSolvingQuiz 상태가 변경되면 오디오 일시정지
  useEffect(() => {
    if (isStartSolvingQuiz && audioInstance) {
      audioInstance.pause();
      setAudio(null);
      setIsPlay(false);
      setPlayingIndex(null);
    }
  }, [isStartSolvingQuiz, audioInstance]);

  // 현재 문장의 오디오 파일 경로를 업데이트하는 로직
  useEffect(() => {
    if (!trainingItems) return;
    if (trainingItems.length === 0) return;
    if (audioUrls.length === 0) return;
    if (audioUrls.length !== trainingItems.length) return;

    const audioPath = audioUrls.find(
      (audio) => audio.index === trainingItemProgress
    );

    if (audioPath) {
      // TODO: why can't use speed?
      loadAudio(audioPath.url, setAudio).catch((err) => {
        console.error("Error loading audio", err);
        setLoadAudioErr(true);
        return;
      });
    }
  }, [audioUrls, trainingItemProgress]);
  //

  // 검사 시작 핸들러
  const handleSolvingQuiz = () => {
    if (audioInstance) {
      audioInstance.pause();
      setAudio(null);
      setIsPlay(false);
      setPlayingIndex(null);
    }
    setIsStartSolvingQuiz(true);
  };

  // 드래그 앤 드롭 로직
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    if (!trainingItems) return;

    const newItems = Array.from(trainingItems);
    const [relocatedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, relocatedItem);
    setTrainingItems(newItems);
  };

  // 정답 확인 로직
  // results 타입 정의
  type Result = {
    isCorrect: boolean;
    correctIndex: number;
  };
  // 각 문장의 정답 위치를 가정한 예시 배열
  const [taskCompletion, setTaskCompletion] =
    useRecoilState(taskCompletionState);

  // 정답 확인 로직을 처리하는 핸들러
  const handleCheckAnswer = () => {
    if (audioInstance) {
      audioInstance.pause();
      setAudio(null);
      setIsPlay(false);
      setPlayingIndex(null);
    }

    if (!trainingItems) return;

    // 정답 순서와 사용자가 배치한 순서를 비교
    const userOrder = trainingItems.map((item) => item.index.toString());
    // const isCorrect =
    //   JSON.stringify(userOrder) === JSON.stringify(correctOrder);

    // 정답 확인 결과를 설정
    const checkResults = trainingItems.map((item, index) => ({
      isCorrect: item.index.toString() === correctOrder[index],
      correctIndex: correctOrder.indexOf(item.index.toString()) + 1
    }));

    setResults(checkResults);

    // const correctCount = checkResults.filter(
    //   (result) => result.isCorrect
    // ).length;
    setIsSolved(true);
    setIsCheckAnswer(true);
    // setMessage("정답률");
  };
  //

  // "다시 풀기" 버튼 클릭 시 상태 초기화
  const handleReset = () => {
    if (audioInstance) {
      audioInstance.pause();
      setAudio(null);
      setIsPlay(false);
      setPlayingIndex(null);
    }
    setMessage(questionMessage);
    setIsCheckAnswer(false);
    setIsSolved(false);
    setResults([]);
  };

  // 뒤로가기 훅
  const navigateToBack = useNavigateToBack({
    setAudioUrls,
    setAudio,
    state,
    day
  });

  // 다음 검사 주제(카테고리)로 이동하는 훅
  const navigateToNext = useNavigateToNext({
    handleReset,
    state,
    day,
    setIsStartSolvingQuiz,
    trainingType: "ORDERING_SENTENCE"
  });

  // 좋아요 기능
  const handleLike = () => {
    setIsLike((prev) => !prev);
  };

  // TODO: temporary fix code
  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (loadAudioErr) {
      // TODO: Error handling on UI
      console.error("Error loading audio");
      // 임시 방편으로 페이지 새로고침
      // reloadPage();
    }
  }, [loadAudioErr]);

  return trainingItems ? (
    <main className="flex justify-center items-center w-full responsive-padding mt-14 lg:mt-16">
      <div style={{ display: "none" }}>
        {audioUrls.map((audio) => (
          <audio key={audio.path} src={audio.url} preload="auto" />
        ))}
      </div>
      <div className="flex flex-col justify-center items-center responsive-max-width">
        <NavigationMain
          property="depth"
          elType="button"
          title={state.title}
          onClick={navigateToBack}
          bgColor="white"
        />

        {/* 제목, 썸네일, 문장(퀴즈) 등이 표시되는 영역 */}
        <section className="flex flex-col justify-center items-center w-full h-full gap-5 mt-5 mb-[72px] lg:mb-[162px]">
          {/* 현재 검사 주제, '좋아요' 버튼을 렌더링하는 컴포넌트 */}
          {!isCompleted && (
            <QuizTopicHeader
              isLike={isLike}
              itemName={itemName as string}
              isStartSolvingQuiz={isStartSolvingQuiz}
              handleLike={handleLike}
              handleSolvingQuiz={() => {
                if (audioInstance) {
                  audioInstance.pause();
                  setAudio(null);
                  setIsPlay(false);
                  setPlayingIndex(null);
                }
                setIsStartSolvingQuiz(false);
              }}
            />
          )}

          {/* 문장 순서화 문장(퀴즈)과 응답 버튼이 렌더링되는 영역 */}
          {/* TODO: USE isLoadingAudio */}
          <SentenceOrderingQuizContent
            state={state}
            playingIndex={playingIndex}
            isStartSolvingQuiz={isStartSolvingQuiz}
            isSolved={isSolved}
            isLoading={isLoadingAudio}
            trainingItems={trainingItems}
            message={message}
            audioUrls={audioUrls}
            orderedAudioUrls={orderedAudioUrls}
            results={results}
            onDragEnd={onDragEnd}
            handlePlayAudio={handlePlayAudio}
            handlePlayAudioForItem={handlePlayAudioForItem}
            handleSolvingQuiz={handleSolvingQuiz}
            handleCheckAnswer={handleCheckAnswer}
            handleReset={handleReset}
            navigateToNext={navigateToNext}
          />
        </section>
      </div>
    </main>
  ) : (
    <></>
  );
}
