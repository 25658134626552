import { useNavigate } from "react-router-dom";
import { ROUTES } from "@constants/common.routes";
import { TrainingType } from "@constants/trainingType";

interface UseNavigateToNextProps {
  handleReset: () => void;
  state: any;
  day?: string;
  setIsStartSolvingQuiz?: React.Dispatch<React.SetStateAction<boolean>>;
  trainingType: keyof typeof TrainingType;
}

export default function useNavigateToNext({
  handleReset,
  state,
  day,
  setIsStartSolvingQuiz,
  trainingType
}: UseNavigateToNextProps) {
  const navigate = useNavigate();
  const { main, sub, page } = state.categoryData;

  const navigateToNext = () => {
    handleReset();
    if (setIsStartSolvingQuiz) setIsStartSolvingQuiz(false);

    const currentItemIndex = state.currentIndex;
    const nextItemIndex = currentItemIndex + 1;
    const nextItem = state.items[nextItemIndex];

    if (!day) {
      if (nextItem) {
        let nextCategoryData = state.categoryData;
        if (nextItem.category_main_code && nextItem.category_sub_code) {
          nextCategoryData = {
            p_code: nextItem.category_main_code,
            code: nextItem.category_sub_code,
            page
          };
        }

        navigate(
          encodeURI(
            `/main/training/${trainingType.toLowerCase()}/${nextItem.name}`
          ),
          {
            state: {
              categoryData: nextCategoryData,
              items: state.items,
              currentIndex: nextItemIndex,
              type: state.type,
              title: state.title
            }
          }
        );
      } else if (main !== undefined || sub !== undefined) {
        navigate(encodeURI(`${ROUTES.CATEGORY}/${main}/${sub}`), {
          state: state.categoryData.data
        });
      } else {
        navigate(
          encodeURI(
            `/main/training/listening_by_training/${trainingType.toLowerCase()}`
          ),
          {
            state: { ...state, page }
          }
        );
      }
    } else {
      let navRoute = `${ROUTES.TODAY.MAIN}`;
      let navRouteOpt;

      if (day === "yesterday") {
        navRoute = `${ROUTES.YESTERDAY.MAIN}`;
      }

      if (nextItem) {
        navRoute =
          navRoute +
          encodeURI(`/${trainingType.toLowerCase()}/${nextItem.name}`);
        navRouteOpt = {
          categoryData: {
            p_code: nextItem.category_main_code,
            code: nextItem.category_sub_code
          },
          items: state.items,
          currentIndex: nextItemIndex,
          type: state.type,
          title: state.title
        };
      }

      navigate(navRoute, { state: navRouteOpt });
    }
  };

  return navigateToNext;
}
