import Lottie from "lottie-react";
import rocketAnimation from "@animations/rocketAnimation.json";

export default function RocketAnimation() {
  return (
    <div className="absolute bottom-0">
      <Lottie animationData={rocketAnimation} />
    </div>
  );
}
