import { useState } from "react";
import useAuth from "@hooks/useAuth";

const useAudioUrl = () => {
  const [loadAudioErr, setLoadAudioErr] = useState<boolean>(false);
  const [isLoadingAudio, setLoadingAudio] = useState<boolean>(true);

  const auth = useAuth();
  const apiUrl =
    "https://u1zwkriinc.execute-api.ap-northeast-2.amazonaws.com/getSound";

  const getAccessToken = async () => {
    const response = await auth.getSbSession();
    const access_token = response.data.session?.access_token;
    if (!access_token) {
      console.error("no access_token");
      throw Error("no access_token");
    }

    return access_token;
  };

  const fetchAudioUrl = async (path: string) => {
    try {
      const access_token = await getAccessToken();
      const res = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
          path: path
        })
      });

      const data = await res.json();
      // console.log("data", data);
      return data.url;
    } catch (err) {
      console.error(err);
      // TODO: error handling
      setLoadAudioErr(true);
      throw Error("Error fetching audio url");
    }
  };

  const fetchAudioUrls = async (paths: string[]) => {
    try {
      const access_token = await getAccessToken();
      const res = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
          paths: paths
        })
      });

      const data = await res.json();
      // console.log("data", data);
      return data;
    } catch (err) {
      console.error(err);
      // TODO: error handling
      setLoadAudioErr(true);
      throw Error("Error fetching audio urls");
    }
  };

  const getAudioElem = (url: string): Promise<HTMLAudioElement> => {
    return new Promise<HTMLAudioElement>((resolve, reject) => {
      const audio = new Audio(url);
      audio.preload = "auto";

      let isLoaded = false;
      const start = new Date().getTime();
      console.log("🚩 audio loaded start:", start);
      const timeout = setTimeout(() => {
        if (!isLoaded) {
          setLoadAudioErr(true);
          reject(new Error("Audio loading timeout"));
        }
      }, 3000);

      audio.oncanplaythrough = () => {
        isLoaded = true;
        clearTimeout(timeout);
        const end = new Date().getTime();
        const duration = end - start;
        console.log(`✅ audio loaded end: ${end} (dur: ${duration}ms)`);
        resolve(audio);
      };

      audio.onerror = () => {
        clearTimeout(timeout);
        const end = new Date().getTime();
        const duration = end - start;
        console.log(`❌ audio loaded end(FAILED): ${end} (dur: ${duration}ms)`);
        setLoadAudioErr(true);
        reject(new Error("Failed to load audio"));
      };

      audio.load();
    });
  };

  const loadAudio = async (
    audioPath: string,
    setAudio: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>,
    speed?: number
  ) => {
    const audio = await getAudioElem(audioPath);
    if (speed) {
      audio.playbackRate = speed;
    } else {
      // TODO: set default speed
      audio.playbackRate = 1;
    }

    setAudio(audio);
    setLoadingAudio(false);
  };

  return {
    loadAudioErr,
    setLoadAudioErr,
    isLoadingAudio,
    setLoadingAudio,
    loadAudio,
    fetchAudioUrl,
    fetchAudioUrls
  };
};

export default useAudioUrl;
