import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Utils from "@libs/utils";
import { ITrainingResponseRecord } from "@libs/common.types";
import type {
  TrainingItemType,
  TrainingResponseType,
  TrainingResponseCountType,
  AudioURLType
} from "@libs/common.types";

// import useAppData from "@hooks/useAppData";
import useBackgroundUrl from "@hooks/useBackgroundUrl";
import useAudioSettings from "@hooks/useAudioSettings";
import useNavigateToNext from "@hooks/useNavigateToNext";
import useNavigateToBack from "@hooks/useNavigateToBack";

import useAudioUrl from "@hooks/useAudioUrl";

import { noiseTypeMap } from "@constants/noiseType";

import QuizTopicHeader from "@components/tests/QuizTopicHeader";
import LowNoiseListeningQuizContent from "@components/tests/quizContents/LowNoiseListeningQuizContent";
import QuizNavigation from "@components/tests/QuizNavigation";
import PopupSetting from "@components/popup/PopupSetting";
import NavigationMain from "@components/common/NavigationMain";
import TestResult from "@components/TestResult";
import BottomSheet from "@components/tests/BottomSheet";
import SoundAnimation from "@components/animation/SoundAnimation";
import SeekBar from "@components/common/SeekBar";

export default function LowNoiseListening() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { itemName, day } = useParams<{ itemName: string; day: string }>();
  // legacy code
  // const { getPublicSoundBucket, getSignedSoundBucket } = useAppData();
  // new fetch audio url
  const {
    loadAudioErr,
    setLoadAudioErr,
    isLoadingAudio,
    setLoadingAudio,
    loadAudio,
    fetchAudioUrl
  } = useAudioUrl();

  const [trainingGroupId, setTrainingGroupId] = useState<string | null>();
  const [trainingItems, setTrainingItems] = useState<TrainingItemType[]>();
  const [trainingItemProgress, setTrainingItemProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [blinkNextButton, setBlinkNextButton] = useState(false); // 응답 시 다음 문제 버튼 애니메이션을 관리하는 상태
  const [audioUrls, setAudioUrls] = useState<AudioURLType[]>([]);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isLike, setIsLike] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isViewSentence, setIsViewSentence] = useState(false);
  const [speed, setSpeed] = useState(1.0); // 재생 속도, 기본 속도 1.0
  const speeds = [0.8, 0.9, 1.0, 1.1, 1.2]; // 속도 옵션 배열
  const [noiseLevel, setNoiseLevel] = useState(0); // 소음크기
  const [noiseType, setNoiseType] = useState("defaultNoise"); // 소음 종류
  const [noiseAudio, setNoiseAudio] = useState<HTMLAudioElement | null>(null); // 소음 종류 오디오
  const [responses, setResponses] = useState<ITrainingResponseRecord>({});
  const [currentResponse, setCurrentResponse] =
    useState<TrainingResponseType | null>(null);
  const [responseCounts, setResponseCounts] =
    useState<TrainingResponseCountType>({
      correct: 0,
      incorrect: 0
    });

  // 배경 이미지 적용 훅
  const { p_code, code } = state.categoryData;
  const backgroundUrl = useBackgroundUrl(p_code, code, isCompleted);

  // 듣기 설정 확장 훅
  const { shouldRender, handleShowSettings } = useAudioSettings();

  // console.log("trainingGroupId", trainingGroupId);
  // console.log("trainingItems", trainingItems);
  // console.log("trainingItemProgress", trainingItemProgress);

  useEffect(() => {
    // escaping direct url loading -> temporary solution
    // TODO: show error massage and redirect to main page
    // TODO: or reload data gracefully
    // console.log("state change event", state);
    if (!state) navigate("/main");
  }, [state]);

  useEffect(() => {
    console.log("audioUrls", audioUrls);
  }, [audioUrls]);

  // 문제 데이터 불러오기
  useEffect(() => {
    if (!state) return;
    const currentItemIndex = state.currentIndex;
    const currentItem = state.items[currentItemIndex];
    const item = Utils.findTrainingItemsById(state.type, currentItem.id);

    if (item) {
      if (!(item.items && item.items.length > 0)) {
        alert("문제 데이터를 준비중입니다.");
        navigateToBack();
        return;
      }
      setTrainingItems(item.items);
      // TrainingItemProgress
      // 0: 준비 상태, 1: 문제풀이 시작번호, trainingItems.length < setTrainingItemProgress: 문제풀이 완료
      setTrainingItemProgress(1);
      setTrainingGroupId(item.id);
    }
  }, [state && state.currentIndex]);
  //

  // "잘들렸어요" 또는 "안들렸어요" 응답 처리 로직
  // 문제 번호 변경 시 응답 초기화
  useEffect(() => {
    /** TODO: Progress 상태 업데이트 */
    // 0: 준비 상태, 1: 문제풀이 시작번호, trainingItems.length < setTrainingItemProgress: 문제풀이 완료
    // inProgress 또는 inComplete
    if (trainingItems && trainingItems.length > 0) {
      setIsCompleted(trainingItemProgress > trainingItems.length);
      setCurrentResponse(null);
    }
  }, [trainingItemProgress]);

  useEffect(() => {
    if (!state) return;
    if (!(trainingItems && trainingItems.length > 0)) return;

    // TODO: 문제 로직마다 다르게 설정해야 할 수 있음.(ex: 짧은 이야기)
    const promisses = trainingItems.map(async (item) => {
      const path = Utils.generateAudioBucketPath(state.type, item.contextCode);
      if (path && path !== "") {
        // const _url = await getSignedSoundBucket(path);
        const _url = await fetchAudioUrl(path);
        return { index: item.index, path: path, url: _url };
      } else {
        throw new Error("Error Audio Path");
      }
    });

    // TODO: 순서보장 확인
    // 만약 예외 확인되면 index로 sort
    Promise.all(promisses)
      .then((results) => results.map((res) => res as AudioURLType))
      .then((urls) => {
        const newValues = urls.filter((url) => url !== undefined);
        setAudioUrls(newValues);
        return newValues;
      })
      .then((newValues) => {
        setAudio(new Audio(newValues[0].url));
        setLoadingAudio(false);
      })
      .catch((err) => {
        console.error(`❌ Failed fetch audio url (ListenInNoise)`);
        console.error(err);
      });
  }, [trainingGroupId]);

  // 문장 보기 버튼을 클릭했을 때의 로직
  const handleViewSentence = () => {
    setIsViewSentence((prev) => !prev);
  };

  // 각 설정(소음크기, 소음종류, 빠르기)에 대한 로직
  // 버튼은 클릭할 때마다 값이 순환됩니다.

  const handleNoiseLevelChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoiseLevel(Number(event.target.value));
  };

  const handleNoiseTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoiseType(event.target.value);
  };

  const handlePreviousQuestion = () => {
    setIsPlay(false);
    if (audio) audio.pause();

    setTrainingItemProgress(trainingItemProgress - 1);
  };

  const handleNextQuestion = () => {
    setIsPlay(false);
    if (audio) audio.pause();

    if (trainingItems) {
      if (trainingItemProgress <= trainingItems.length) {
        setTrainingItemProgress(trainingItemProgress + 1);
        setIsViewSentence(false);
      } else {
        setIsViewSentence(false); // 결과 화면으로 넘어갈 때 문장 보기 상태 초기화
      }
    }

    // "다음 문제" 버튼 강조 효과 제거
    setBlinkNextButton(false);
  };
  //

  const handleResponse = (response: TrainingResponseType) => {
    if (currentResponse === response) return; // 응답이 변경되지 않으면 아무것도 하지 않음

    setResponses((prev) => {
      const updatedResponses = { ...prev, [trainingItemProgress]: response };

      // 응답 개수를 업데이트하기 위해 이전 응답을 제거하고 새 응답을 추가
      const prevResponse = prev[trainingItemProgress];
      setResponseCounts((prevCounts) => {
        const newCounts = { ...prevCounts };
        if (prevResponse) {
          newCounts[prevResponse.response] -= 1;
        }
        newCounts[response.response] += 1;
        return newCounts;
      });

      return updatedResponses;
    });

    setCurrentResponse(response);

    // 응답 후 "다음 문제" 버튼에 강조 효과 추가
    setBlinkNextButton(true);
  };

  const handlePlay = () => {
    setIsPlay((prev) => !prev);
  };

  // 현재 문장의 오디오 파일 경로를 업데이트하는 로직
  useEffect(() => {
    if (!trainingItems) return;
    if (trainingItems.length === 0) return;
    if (audioUrls.length === 0) return;
    if (audioUrls.length !== trainingItems.length) return;

    const audioPath = audioUrls.find(
      (audio) => audio.index === trainingItemProgress
    );

    if (audioPath) {
      loadAudio(audioPath.url, setAudio, speed).catch((err) => {
        console.error("Error loading audio", err);
        setLoadAudioErr(true);
        return;
      });
    }
  }, [audioUrls, trainingItemProgress]);

  useEffect(() => {
    if (!audio) return;

    if (isPlay) {
      audio.play();
    } else {
      audio.pause();
    }

    audio.onended = () => {
      setIsPlay(false);
    };

    return () => {
      audio.pause();
    };
  }, [audio, isPlay]);

  // 소음 사운드 관리
  useEffect(() => {
    if (!noiseType || noiseLevel === null) return;

    // 소음 파일 경로 생성 함수
    const generateNoiseAudioPath = (type: string) => {
      const randomIndex = Math.floor(Math.random() * 10) + 1;
      switch (type) {
        case "defaultNoise":
          return `${process.env.PUBLIC_URL}/sounds/noise/noise1.mp3`;
        case "street":
          return `${process.env.PUBLIC_URL}/sounds/noise/noise2-${String(
            randomIndex
          ).padStart(2, "0")}.mp3`;
        case "restaurant":
          return `${process.env.PUBLIC_URL}/sounds/noise/noise3-${String(
            randomIndex
          ).padStart(2, "0")}.mp3`;
        case "buzz":
          return `${process.env.PUBLIC_URL}/sounds/noise/noise4.mp3`;
        default:
          return "";
      }
    };

    const noiseAudioPath = generateNoiseAudioPath(noiseType);
    if (!noiseAudioPath) return;

    const noiseAudioInstance = new Audio(noiseAudioPath);
    noiseAudioInstance.volume = noiseLevel * 0.25; // 볼륨 조정

    // 소음 재생 및 상태 관리 함수
    const controlNoisePlayback = () => {
      if (isPlay) {
        noiseAudioInstance
          .play()
          .catch((err) => console.error("Noise playback error:", err));
      } else {
        noiseAudioInstance.pause();
        noiseAudioInstance.currentTime = 0;
      }
    };

    setNoiseAudio(noiseAudioInstance);
    controlNoisePlayback();

    // 소음 반복 재생 처리
    noiseAudioInstance.onended = () => {
      noiseAudioInstance.currentTime = 0;
      if (isPlay) noiseAudioInstance.play();
    };

    // 컴포넌트 언마운트 시 정리
    return () => {
      noiseAudioInstance.pause();
      noiseAudioInstance.currentTime = 0;
    };
  }, [noiseType, noiseLevel, isPlay]);

  const handleReset = () => {
    setIsPlay(false); // 문제 듣기 상태를 초기화
    setIsViewSentence(false); // 문장 보기 상태를 초기화
    setTrainingItemProgress(1); // 테스트 진행 상황 초기화
    setIsCompleted(false); // 테스트 완료 상태를 초기화
    setResponseCounts({ correct: 0, incorrect: 0 }); // 응답 카운트 초기화
    setResponses({}); // 기존 응답 기록 초기화
    setCurrentResponse(null); // 현재 응답 상태를 초기화
  };

  // 뒤로가기 훅
  const navigateToBack = useNavigateToBack({
    setAudioUrls,
    setAudio,
    state,
    day
  });

  // 다음 검사 주제(카테고리)로 이동하는 훅
  const navigateToNext = useNavigateToNext({
    handleReset,
    state,
    day,
    trainingType: "LISTEN_IN_NOISE"
  });

  // 좋아요 기능
  const handleLike = () => {
    setIsLike((prev) => !prev);
  };

  // 현재 진행 중인 문제 / 전체 문제 개수
  const progressCount = !isCompleted
    ? trainingItemProgress
    : trainingItems && trainingItems.length;
  const progressTotalCount = trainingItems && trainingItems.length;

  // 재생 속도 관리
  useEffect(() => {
    if (audio) {
      audio.playbackRate = speed;
    }
  }, [audio, speed]);

  // TODO: temporary fix code
  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (loadAudioErr) {
      // TODO: Error handling on UI
      console.error("Error loading audio");
      // 임시 방편으로 페이지 새로고침
      // reloadPage();
    }
  }, [loadAudioErr]);

  return trainingItems ? (
    <main className="flex justify-center items-center w-full responsive-padding mt-14 lg:mt-16">
      <div className="flex flex-col justify-center items-center responsive-max-width">
        <NavigationMain
          property="depth"
          elType="button"
          title={state.title}
          onClick={navigateToBack}
          bgColor="white"
        />

        {/* 제목, 썸네일, 문장(퀴즈) 등이 표시되는 영역 */}
        <section className="flex flex-col justify-center items-center w-full h-full gap-5 mt-5 mb-[72px] lg:mb-[162px]">
          {/* 현재 검사 주제, '좋아요' 버튼을 렌더링하는 컴포넌트 */}
          {!isCompleted && (
            <QuizTopicHeader
              isLike={isLike}
              isViewSentence={isViewSentence}
              itemName={itemName as string}
              handleLike={handleLike}
              handleViewSentence={handleViewSentence}
            />
          )}

          {/* 배경 이미지를 렌더링하는 영역 */}
          {!isCompleted && (
            <div className="relative flex flex-col w-full h-[168px] lg:h-[382px]">
              {isPlay && <SoundAnimation />}
              {backgroundUrl && (
                <img
                  className="rounded-[20px] h-full w-full object-cover"
                  src={backgroundUrl}
                  alt="Background Image"
                />
              )}

              <p className="absolute bottom-3 right-3 py-2 px-4 bg-black/60 text-white text-sm rounded-[100px]">
                {progressCount}
                &nbsp;/&nbsp;
                {progressTotalCount}
              </p>
            </div>
          )}

          {/* 소음 하 문장(퀴즈)과 응답 버튼이 렌더링되는 영역 */}
          {!isCompleted ? (
            <LowNoiseListeningQuizContent
              isCompleted={isCompleted}
              isViewSentence={isViewSentence}
              trainingItems={trainingItems}
              trainingItemProgress={trainingItemProgress}
              currentResponse={currentResponse as TrainingResponseType}
              handleViewSentence={handleViewSentence}
              handleResponse={handleResponse}
            />
          ) : (
            <TestResult
              category={state.type}
              isCompleted={isCompleted}
              isViewSentence={isViewSentence}
              responseCounts={responseCounts}
              handleReset={handleReset}
              navigateToNext={navigateToNext}
              state={state}
            />
          )}

          {/* Seek Bar */}
          {!isCompleted && (
            <SeekBar
              audio={audio}
              isPlay={isPlay}
              progressCount={progressCount as number}
            />
          )}

          {/* 이전/다음 문제 이동 버튼, 플레이 버튼을 렌더링하는 컴포넌트 */}
          {!isCompleted && (
            <QuizNavigation
              isCompleted={isCompleted}
              blinkNextButton={blinkNextButton}
              trainingItemProgress={trainingItemProgress}
              handlePreviousQuestion={handlePreviousQuestion}
              handleNextQuestion={handleNextQuestion}
              handlePlay={handlePlay}
              isLoadingAudio={isLoadingAudio}
              isPlay={isPlay}
            />
          )}
        </section>

        {/* 현재 오디오 설정을 요약하여 제공 */}
        {!isCompleted && (
          <BottomSheet
            speed={speeds.indexOf(speed)}
            noiseLevel={noiseLevel}
            noiseType={noiseType}
            noiseTypeMap={noiseTypeMap}
            handleShowSettings={handleShowSettings}
          />
        )}
      </div>

      {/* {loadAudioErr && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-md">
            <p className="mb-4">오디오를 불러오는 데 문제가 발생했습니다.</p>
            <button
              onClick={() => {
                console.log("retry");
                window.location.reload();
              }}
            >
              다시 시도
            </button>
          </div>
        </div>
      )} */}

      {/* 빠르기 등 오디오 설정 팝업 */}
      {shouldRender && !isCompleted && (
        <PopupSetting
          speedOptions={speeds}
          speed={speed}
          noiseType={noiseType}
          noiseLevel={noiseLevel}
          setSpeed={setSpeed}
          handleShowSettings={handleShowSettings}
          handleNoiseLevelChange={handleNoiseLevelChange}
          handleNoiseTypeChange={handleNoiseTypeChange}
        />
      )}
    </main>
  ) : (
    <></>
  );
}
