import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";

import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";

type BottomSheetProps = {
  hintNumber: number;
  hintType: "horizontal" | "vertical";
  audioUrls: { [key: string]: string }; // Hint1, Hint2, Hint3, Answer URL
};

export default function BottomSheet({
  hintNumber,
  hintType,
  audioUrls
}: BottomSheetProps) {
  const { quiz } = useParams();
  const [playingAudio, setPlayingAudio] = useState<string | null>(null); // 현재 재생 중인 오디오
  const audioRef = useRef<HTMLAudioElement | null>(null); // 현재 재생 중인 오디오 객체 참조

  const playAudio = async (
    fileType: "Hint1" | "Hint2" | "Hint3" | "Answer"
  ) => {
    if (audioUrls[fileType]) {
      // 기존 오디오 중지
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = null;
      }

      // 동일 오디오 클릭 시 재생 중지
      if (playingAudio === fileType) {
        setPlayingAudio(null);
        return;
      }

      try {
        // 새로운 오디오 재생
        const audio = new Audio(audioUrls[fileType]);
        audioRef.current = audio;
        setPlayingAudio(fileType);
        // TODO: audio.play() after 0.5 seconds
        audioRef.current.currentTime = 0.5;
        await audio.play(); // 비동기 재생 요청
        audio.onended = () => {
          setPlayingAudio(null);
        };
      } catch (err) {
        console.error("오디오 재생 중 오류 발생:", err);
        setPlayingAudio(null);
      }
    } else {
      alert("오디오를 로드할 수 없습니다.");
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        try {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          audioRef.current = null;
          setPlayingAudio(null);
        } catch (err) {
          console.error("오디오 중지 중 오류 발생:", err);
        }
      }
    };
  }, [hintNumber, quiz]);

  return (
    <div className="fixed bottom-0 flex flex-col justify-between items-center w-full h-36 py-3 px-5 shadow-[0px_-4px_6px_0px_#00000029] rounded-t-[20px] bg-white max-w-[1200px] lg:h-60">
      <p className="w-full text-left font-bold leading-none lg:text-2xl">
        <span
          className={
            hintType === "horizontal" ? "text-mainColor1" : "text-orange"
          }
        >
          문제 {hintNumber}{" "}
        </span>
        힌트
      </p>
      <div className="flex justify-center items-center gap-3 w-full text-sm lg:text-2xl lg:gap-3">
        {Object.entries(audioUrls)
          .filter(([key]) => key.startsWith("Hint"))
          .sort(([a], [b]) => a.localeCompare(b)) // Hint1, Hint2, Hint3 순서 보장
          .map(([key], index) => (
            <button
              key={key} // index를 사용하지 않도록 수정
              className="flex-1 flex justify-center items-center gap-2 rounded-xl py-1 px-3 border border-solid border-gray30"
              onClick={() => playAudio(key as "Hint1" | "Hint2" | "Hint3")}
            >
              <p className="text-base text-gray70 font-bold leading-none lg:text-2xl">
                {index + 1}
              </p>
              <ReactSVG
                className="size-4 fill-gray70 lg:size-6"
                src={`${
                  playingAudio === key
                    ? `${process.env.PUBLIC_URL}/images/icons_update/ico_pause.svg`
                    : `${process.env.PUBLIC_URL}/images/icons_update/ico_play.svg`
                }`}
              />
            </button>
          ))}
      </div>
      <ButtonsMainFilled1
        className="p-3 text-base lg:p-5 lg:text-2xl"
        type="button"
        mode="enable/icon"
        text="정답듣기"
        iconName={playingAudio === "Answer" ? "ico_pause" : "ico_play"}
        iconStyle="size-6 fill-white"
        onClick={() => playAudio("Answer")}
      />
    </div>
  );
}
