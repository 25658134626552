import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate, useParams } from "react-router-dom";

import { ContextType } from "@libs/common.types";

import useAppData from "@hooks/useAppData";

import { CrosswordState } from "@states/CrosswordState";

import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import ButtonsMainOutlined from "@components/common/buttons/ButtonsMainOutlined";
import {
  GRID_COLS,
  GRID_ROWS,
  GRID_MAX_WIDTH
} from "@constants/crosswordStyles";
import { getCellBorderStyle, getCornerStyle } from "@helpers/crosswordUtils";

type CrosswordResultModalProps = {
  col: number;
  row: number;
  existCells: number[];
  vertical: number[][];
  horizontal: number[][];
  userAnswers: { [key: number]: ContextType | undefined };
  questions: {
    key: string;
    answerContext: string;
    cells: number[];
  }[];
  onClose: () => void;
  onRetry: () => void;
};

export default function CrosswordResultModal({
  col,
  row,
  existCells,
  vertical,
  horizontal,
  userAnswers,
  questions,
  onClose,
  onRetry
}: CrosswordResultModalProps) {
  const navigate = useNavigate();

  // 현재 퀴즈 번호
  const { quiz } = useParams();

  // 문제 번호를 담는 Map 생성 (cellIndex를 키로 사용)
  const verticalMap = new Map(vertical.map(([cell, number]) => [cell, number]));
  const horizontalMap = new Map(
    horizontal.map(([cell, number]) => [cell, number])
  );

  // 채점 매핑 생성: cellIndex => { 기대되는 문자, 정답 여부 }
  const grading = useMemo(() => {
    const map: {
      [key: number]: { expectedLetter: string; isCorrect: boolean };
    } = {};
    questions.forEach((q) => {
      // answerContext의 문자가 q.cells에 순서대로 대응한다고 가정
      q.cells.forEach((cell, idx) => {
        const expectedLetter = q.answerContext[idx] || "";
        const userLetter = userAnswers[cell]?.letter || "";
        map[cell] = {
          expectedLetter,
          isCorrect: userLetter === expectedLetter
        };
      });
    });
    return map;
  }, [questions, userAnswers]);

  // test fetch crossword log
  const appData = useAppData();
  const crosswordState = useRecoilValue(CrosswordState);
  useEffect(() => {
    appData.getCurrentCrosswordLog();
  }, []);
  // END test fetch crossword log

  // 문제 이동 시 스크롤 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [quiz]);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 text-center w-screen px-5 md:px-5 lg:px-0 break-keep"
      onClick={() => {
        // onRetry();
        onClose();
      }}
    >
      <div
        className="flex flex-col items-center gap-4 w-full bg-white p-4 rounded-[20px] shadow-lg max-w-[600px]"
        onClick={(e) => e.stopPropagation()}
      >
        <p className="w-full text-left text-lg font-bold">
          <span className="text-mainColor1">{quiz}단계</span> 해답
        </p>
        <div
          className={`w-full grid ${GRID_COLS[col]} ${GRID_ROWS[row]} ${GRID_MAX_WIDTH[col]} border border-solid border-gray50 rounded-xl overflow-hidden lg:h-auto`}
        >
          {Array.from({ length: col * row }).map((_, index) => {
            const cellIndex = index + 1; // 셀 번호 (1부터 시작)
            const isExistCell = existCells.includes(cellIndex); // 존재하는 셀 여부
            const isWrong = grading[cellIndex]?.isCorrect === false; // 오답 여부
            const isVertical = verticalMap.has(cellIndex); // 세로 문제 여부
            const isHorizontal = horizontalMap.has(cellIndex); // 가로 문제 여부
            const cellGrading = grading[cellIndex]; // 채점 정보 (문자, 정답 여부)

            const cellBorderStyle = getCellBorderStyle(cellIndex, col, row); // 기본 테두리 스타일
            const cornerStyle = getCornerStyle(cellIndex, col, row); // 모서리 스타일

            return (
              <div
                key={cellIndex}
                className={`relative flex justify-center items-center aspect-square size-auto max-w-[72px] max-h-[72px] lg:max-w-36 lg:max-h-36 ${
                  isWrong
                    ? "bg-[#FFEAEA] border border-solid border-[#FF3535]"
                    : isExistCell
                    ? "bg-white cursor-pointer"
                    : "bg-gray30 cursor-not-allowed"
                } ${
                  cornerStyle && cellGrading && !cellGrading.isCorrect
                    ? cornerStyle
                    : ""
                } ${
                  // 만약 오답인 경우, 덮어쓰는 border 스타일 적용
                  isWrong ? "" : cellBorderStyle
                }`}
              >
                <p
                  className={`font-bold text-[32px] lg:text-[56px] ${
                    isWrong ? "text-red80" : "text-gray80"
                  }`}
                >
                  {userAnswers[cellIndex]?.letter || ""}
                </p>

                {/* 한 셀에 가로/세로 문제 번호가 함께 있는 경우 */}
                {isVertical && isHorizontal && (
                  <>
                    <span className="absolute top-1 left-1 text-xs font-bold text-mainColor1">
                      {horizontal.find(([cell]) => cell === cellIndex)?.[1]}
                    </span>
                    <span className="absolute top-1 right-1 text-xs font-bold text-orange">
                      {vertical.find(([cell]) => cell === cellIndex)?.[1]}
                    </span>
                  </>
                )}

                {/* 단일 문제 번호 처리 */}
                {!isVertical && isHorizontal && (
                  <span className="absolute top-1 left-1 text-xs font-bold text-mainColor1">
                    {horizontal.find(([cell]) => cell === cellIndex)?.[1]}
                  </span>
                )}
                {isVertical && !isHorizontal && (
                  <span className="absolute top-1 left-1 text-xs font-bold text-orange">
                    {vertical.find(([cell]) => cell === cellIndex)?.[1]}
                  </span>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex gap-3 w-full">
          <ButtonsMainOutlined
            className="p-3 text-base max-sm:text-sm lg:text-xl"
            type="button"
            mode="enable/icon"
            text="다시풀기"
            iconName="ico_refresh"
            iconStyle="size-6 fill-mainColor1"
            onClick={() => {
              onRetry();
              onClose();
            }}
          />
          <ButtonsMainFilled1
            className="p-3 text-base max-sm:text-sm lg:text-xl"
            type="button"
            mode={Number(quiz) === 100 ? "enable" : "enable/icon"}
            text={Number(quiz) === 100 ? "메뉴로이동" : "다음퀴즈"}
            iconName="ico_play"
            iconStyle="size-6 fill-white"
            onClick={async () => {
              const currentQuizNumber = Number(quiz);
              await appData.updateCrosswordLog(currentQuizNumber);
              navigate(`/main/training/crossword/${currentQuizNumber + 1}`);
            }}
          />
        </div>
      </div>
    </div>
  );
}
