import { ReactSVG } from "react-svg";
import { CommonButtonsProps } from "src/types/props.types";

export default function ButtonsMainFilled1({
  mode,
  text,
  type,
  onClick,
  className,
  iconName,
  iconStyle
}: CommonButtonsProps) {
  const btnStyle = mode.includes("enable")
    ? "bg-mainColor1 text-white border-2 border-solid border-mainColor1"
    : mode.includes("disable")
    ? "bg-gray20 text-gray50"
    : "";

  return (
    <button
      className={`buttons-main-filled-1 ${btnStyle} ${
        mode.includes("icon")
          ? "flex justify-center items-center gap-[10px]"
          : ""
      } ${className || ""}`}
      type={type as "button" | "submit" | "reset"}
      onClick={onClick}
    >
      {mode.includes("icon") && (
        <ReactSVG
          className={iconStyle}
          src={`${process.env.PUBLIC_URL}/images/icons_update/${iconName}.svg`}
        />
      )}
      {text}
    </button>
  );
}
