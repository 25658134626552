import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  useWatch
} from "react-hook-form";
import { AuthError } from "@supabase/supabase-js";

import { UserAuthState } from "@states/UserAuthState";
import Utils from "@libs/utils";
import type { AuthInputType } from "@libs/common.types";

import useAuth from "@hooks/useAuth";

import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import TextFieldMain from "@components/common/textfield/TextfieldMain";
import LoadingSpinner from "@components/LoadingSpinner";
import Alert from "@components/common/Alert";
import NavigationMain from "@components/common/NavigationMain";

export default function SignUp() {
  const auth = useAuth();
  const navigate = useNavigate();
  const uaState = useRecoilValue(UserAuthState);

  useEffect(() => {
    console.log("sessionState", uaState);
  }, [uaState]);

  const [profileErrorMessage, setProfileErrorMessage] = useState<
    string | undefined | null
  >();
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined | null
  >();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | undefined | null
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false); // 로딩 상태
  const [alertMessage, setAlertMessage] = useState<string | null>(null); // Alert 메시지 상태
  const [isTouched, setIsTouched] = useState({
    email: false,
    password: false,
    profileName: false
  });

  useEffect(() => {
    if (uaState.user && uaState.isAuthenticated) {
      navigate("/main");
    }
  }, [uaState.user, navigate]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    control
  } = useForm<AuthInputType>();

  const watchedValues = useWatch({ control });

  // 입력 시작 시 입력값 유효성 검사
  useEffect(() => {
    if (isTouched.email) {
      setEmailErrorMessage(Utils.validateEmail(watchedValues.email as string));
    }
    if (isTouched.password) {
      setPasswordErrorMessage(
        Utils.validatePassword(watchedValues.password as string)
      );
    }
    if (isTouched.profileName) {
      setProfileErrorMessage(
        Utils.validateProfileName(watchedValues.profile?.name as string)
      );
    }
  }, [watchedValues, isTouched]);

  const onSubmit: SubmitHandler<AuthInputType> = async (data: any) => {
    console.log("onSubmit", data);
    setEmailErrorMessage(null);
    setPasswordErrorMessage(null);
    setProfileErrorMessage(null);

    setIsLoading(true); // 로딩 상태 활성화
    try {
      const result = await auth.signUp(data);
      console.log("onSubmit::result", result);
      if (result) {
        setIsLoading(false); // 로딩 종료 후 Alert 표시
        setAlertMessage("인증메일이 발송되었습니다. 확인 후 로그인해주세요.");
        // navigate("/login"); // navigate 로직은 Alert 컴포넌트에서 처리
      }
    } catch (error) {
      const err = error as AuthError;
      console.error("onSubmit::error", err.message);
      if (err.message === "Email rate limit exceeded") {
        setAlertMessage(
          "이메일 인증 요청이 너무 많습니다. 잠시 후 다시 시도해주세요."
        );
      } else {
        setAlertMessage(
          "회원가입 오류가 발생하였습니다.\n이메일 또는 비밀번호를 확인하여 주세요."
        );
      }
      // TODO : more error exception handle
    } finally {
      setIsLoading(false); // 로딩 상태 비활성화
    }
  };

  const onError: SubmitErrorHandler<AuthInputType> = (errors) => {
    console.log("onError", errors);
    if (errors.email) {
      setEmailErrorMessage(errors.email.message);
      setIsTouched((prev) => ({ ...prev, email: true }));
    }
    if (errors.password) {
      setPasswordErrorMessage(errors.password.message);
      setIsTouched((prev) => ({ ...prev, password: true }));
    }
    if (errors.profile?.name) {
      setProfileErrorMessage(errors.profile.name.message);
      setIsTouched((prev) => ({ ...prev, profileName: true }));
    }
  };

  // 페이지 접속 시 스크롤 최상단으로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Alert 컴포넌트 사용 */}
      {!isSubmitting && alertMessage && (
        <Alert
          message={alertMessage}
          onClose={() => {
            setAlertMessage(null);
            navigate("/login");
          }}
        />
      )}
      <main className="flex justify-center items-center w-screen h-dvh bg-gray10 responsive-padding">
        {isSubmitting && <LoadingSpinner />} {/* 로딩 스피너 표시 */}
        <div className="flex flex-col justify-center items-center responsive-max-width">
          <NavigationMain
            property="depth"
            elType="link"
            to="/login"
            title="계정만들기"
            bgColor="white"
          />
          <section className="flex flex-col justify-start items-center gap-3 w-full py-10 lg:px-0 lg:gap-16 ">
            <p className="font-GmarketSansBold font-medium text-4xl text-mainColor1 text-center max-lg:text-[28px] max-lg:leading-8 lg:mt-0">
              아이해브말귀 <br />{" "}
              <span className="text-[#1a1a1a]">회원가입</span>
            </p>
            <form
              id="signupForm"
              className="flex flex-col gap-6 w-full max-w-[600px] lg:gap-9"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="flex flex-col gap-3 lg:gap-4">
                <p className="text-lg font-bold">이메일</p>
                <TextFieldMain
                  id="email"
                  type="email"
                  placeholder="이메일을 입력해 주세요"
                  iconName="ico_email"
                  errorMessage={emailErrorMessage}
                  register={register("email", {
                    required: "이메일은 필수 입력 항목입니다.",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                      message: "이메일 형식이 아닙니다."
                    },
                    onChange: () =>
                      setIsTouched((prev) => ({ ...prev, email: true }))
                  })}
                />
                {emailErrorMessage && (
                  <p className="text-red80">{emailErrorMessage}</p>
                )}
              </div>
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex flex-col gap-3 lg:gap-4">
                  <p className="text-lg font-bold">비밀번호</p>
                  <TextFieldMain
                    id="password"
                    type="password"
                    placeholder="비밀번호를 입력해 주세요"
                    iconName="ico_password"
                    errorMessage={passwordErrorMessage}
                    register={register("password", {
                      required: "비밀번호는 필수 입력 항목입니다.",
                      minLength: {
                        value: 8,
                        message: "비밀번호는 8자 이상이여야 합니다."
                      },
                      onChange: () =>
                        setIsTouched((prev) => ({ ...prev, password: true }))
                    })}
                  />
                  {passwordErrorMessage && (
                    <p className="text-red80">{passwordErrorMessage}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-3 lg:gap-4">
                <p className="text-lg font-bold">사용자명</p>
                <TextFieldMain
                  id="name"
                  type="text"
                  placeholder="사용자명을 입력해 주세요"
                  iconName="ico_profile"
                  errorMessage={profileErrorMessage}
                  register={register("profile.name", {
                    required: "사용자명은 필수 입력 항목입니다.",
                    minLength: {
                      value: 2,
                      message: "사용자명은 2자 이상이여야 합니다."
                    },
                    maxLength: {
                      value: 12,
                      message: "사용자명은 12자 이하이여야 합니다."
                    },
                    onChange: () =>
                      setIsTouched((prev) => ({ ...prev, profileName: true }))
                  })}
                />
                {profileErrorMessage && (
                  <p className="text-red80">{profileErrorMessage}</p>
                )}
              </div>

              <ButtonsMainFilled1
                className="p-3 text-lg lg:p-5 lg:text-2xl"
                type="submit"
                mode="enable"
                text="회원가입"
              />
            </form>
          </section>
        </div>
      </main>
    </>
  );
}
