import Lottie from "lottie-react";
import soundAnimation from "@animations/soundAnimation.json";

export default function SoundAnimation() {
  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
      <Lottie
        className="w-20 lg:w-40"
        animationData={soundAnimation}
        loop={true}
      />
    </div>
  );
}
