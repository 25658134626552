import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { UserAuthState } from "@states/UserAuthState";
import useAppData from "@hooks/useAppData";
import { LAYOUT_ROUTES, ROUTES } from "@constants/common.routes";
import NavigationMain from "@components/common/NavigationMain";

export default function Navigations({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, isAuthenticated } = useRecoilValue(UserAuthState);
  const isUserAuthenticated = user && isAuthenticated;

  const { getTodayTrainingLog } = useAppData();

  useEffect(() => {
    if (!isUserAuthenticated) {
      alert("로그인이 필요한 페이지입니다.");
      navigate("/login");
    } else {
      getTodayTrainingLog();
    }
  }, []);

  // 헤더를 렌더링하지 않을 경로
  const pathsWithoutHeader = [
    "/main/myPage", // 마이 페이지
    `/main${LAYOUT_ROUTES.CATEGORY}/:main/:sub`, // 주제별 듣기 카테고리 선택 경로

    `/main/training/:day?/listen_in_noise/:itemName`, // 소음 하 경로
    `/main/training/:day/listen_in_noise/:itemName`, // 선택적 세그먼트 대신 필수 세그먼트 추가
    `/main/training/listen_in_noise/:itemName`, // 선택적 세그먼트를 제외한 경로

    `/main/training/:day?/short_story/:itemName`, // 짧은 이야기 경로
    `/main/training/:day/short_story/:itemName`, // 선택적 세그먼트 대신 필수 세그먼트 추가
    `/main/training/short_story/:itemName`, // 선택적 세그먼트를 제외한 경로

    `/main/training/:day?/long_story/:itemName`, // 긴 이야기 경로
    `/main/training/:day/long_story/:itemName`, // 선택적 세그먼트 대신 필수 세그먼트 추가
    `/main/training/long_story/:itemName`, // 선택적 세그먼트를 제외한 경로

    `/main/training/:day?/ordering_sentence/:itemName`, // 문장 순서화 경로
    `/main/training/:day/ordering_sentence/:itemName`, // 선택적 세그먼트 대신 필수 세그먼트 추가
    `/main/training/ordering_sentence/:itemName` // 선택적 세그먼트를 제외한 경로
  ];

  const decodedPathname = decodeURIComponent(location.pathname);

  const isPathWithoutHeader = pathsWithoutHeader.some((path) => {
    const match = matchPath({ path, end: true }, decodedPathname);
    return match !== null;
  });

  // 경로가 /listening_by_training/를 포함하면 헤더를 렌더링
  const shouldRenderHeader =
    !isPathWithoutHeader ||
    location.pathname.includes("/listening_by_training/");

  // 가로세로퀴즈 페이지 여부 확인 (/main/training/crossword)
  const isCrossword = location.pathname === ROUTES.CROSSWORD;

  // TODO: 로그인 중 ?? 로그인 실패 ?? 데이터 로딩 중 ?? 데이터 로딩 완료
  return !isAuthenticated ? null : (
    <>
      {shouldRenderHeader && (
        <NavigationMain
          property="main"
          isCrossword={isCrossword}
        />
      )}
      {children}
    </>
  );
}
