import { TrainingResponseType } from "@libs/common.types";

import ButtonsMainOutlined from "@components/common/buttons/ButtonsMainOutlined";
import ButtonsSubFilled from "@components/common/buttons/ButtonsSubFilled";

type LowNoiseListeningQuizContentProps = {
  isCompleted: boolean;
  isViewSentence: boolean;
  trainingItemProgress: number;
  handleViewSentence: () => void;
  trainingItems: { context: string }[];
  handleResponse: (response: TrainingResponseType) => void;
  currentResponse: TrainingResponseType | null;
};

export default function LowNoiseListeningQuizContent({
  isCompleted,
  isViewSentence,
  handleViewSentence,
  trainingItems,
  trainingItemProgress,
  currentResponse,
  handleResponse
}: LowNoiseListeningQuizContentProps) {
  return (
    <div
      className={`flex flex-col items-center w-full h-36 lg:h-40 ${
        !isViewSentence && !isCompleted
          ? "justify-between"
          : "justify-center gap-6 lg:gap-10"
      }`}
    >
      {!isViewSentence && !isCompleted && (
        <>
          <p className="text-lg break-keep font-bold text-center lg:text-[32px]">
            아래 플레이(▶️) 버튼을 눌러 문장을 잘 들어보세요.
          </p>
          <p className="text-lg break-keep font-bold text-center lg:text-[32px]">
            잘 들으신 뒤 문장을 확인하세요.
          </p>
          <ButtonsSubFilled
            className="py-2 px-3 lg:text-2xl lg:p-4"
            type="button"
            mode="enable"
            text="문장확인"
            onClick={handleViewSentence}
          />
        </>
      )}
      {isViewSentence && !isCompleted && (
        <>
          <p className="text-lg font-bold text-center break-keep lg:text-[32px]">
            {trainingItems[trainingItemProgress - 1].context}
          </p>
          <div className="flex justify-center items-center gap-3 w-full lg:max-w-[600px]">
            <ButtonsMainOutlined
              className={`p-3 ${
                currentResponse?.response === "correct"
                  ? "bg-mainColor1"
                  : "border-gray40"
              }`}
              type="button"
              mode="enable/icon"
              text="잘들렸어요"
              iconName="ico_check"
              iconStyle={`size-6 ${
                currentResponse?.response === "correct"
                  ? "fill-white"
                  : "fill-mainColor1"
              }`}
              textStyle={
                currentResponse?.response === "correct"
                  ? "text-white"
                  : "text-mainColor1"
              }
              onClick={() =>
                handleResponse({ answer: "T", response: "correct" })
              }
            />
            <ButtonsMainOutlined
              className={`p-3 ${
                currentResponse?.response === "incorrect"
                  ? "bg-mainColor1"
                  : "border-gray40"
              }`}
              type="button"
              mode="enable/icon"
              text="안들렸어요"
              iconName="ico_x"
              iconStyle={`size-6 ${
                currentResponse?.response === "incorrect"
                  ? "stroke-white"
                  : "stroke-mainColor1"
              }`}
              textStyle={
                currentResponse?.response === "incorrect"
                  ? "text-white"
                  : "text-mainColor1"
              }
              onClick={() =>
                handleResponse({ answer: "F", response: "incorrect" })
              }
            />
          </div>
        </>
      )}
    </div>
  );
}
