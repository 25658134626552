import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { CategoryMainState } from "@states/DataTypeState";

import TabsSubject from "@components/TabsSubject";
import CategoryButton from "@components/CategoryButton";

type Refs = {
  [key: string]: MutableRefObject<HTMLAnchorElement | null>;
};

export default function TopicListeningSelect() {
  const location = useLocation();
  const categoryMainState = useRecoilValue(CategoryMainState);
  const { data } = categoryMainState;
  const { main } = useParams<{ main?: string }>();

  const [currentCategoryMain, setCurrentCategoryMain] = useState();

  // move to Utils
  // const { addSpacingToWords } = Utils;

  useEffect(() => {
    if (main && data && data.length > 0) {
      setCurrentCategoryMain(data.find((d: any) => d.route === main) || null);
    }
  }, [main, data]);

  // 각 아이템에 대한 ref 배열을 생성

  const refs: Refs = data.reduce((acc: Refs, value: any) => {
    acc[value.route] = useRef<HTMLAnchorElement>(null);
    return acc;
  }, {});

  // 활성화된 아이템이 변경될 때 스크롤 이동
  useEffect(() => {
    const activeTopic = location.pathname.split("/").pop() || ""; // 빈 문자열로 기본값 설정
    const activeRef = refs[activeTopic];
    if (activeRef && activeRef.current) {
      activeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }, [location, refs]);

  return (
    <main className="flex justify-center items-center w-full responsive-padding mb-[72px] mt-14 lg:mt-16">
      <div className="flex flex-col justify-center items-center responsive-max-width">
        <div className="flex flex-col justify-center items-center mt-4">
          <p className="text-2xl text-black font-bold mb-1 lg:text-[32px]">
            주제별 듣기
          </p>
          <p className="text-sm text-gray60 lg:text-xl">
            원하는 주제를 선택하세요
          </p>
        </div>

        <div className="flex items-start gap-2 w-full mt-5 mb-8 overflow-x-scroll lg:overflow-visible lg:justify-center lg:gap-2 lg:mt-[36px] lg:mb-8">
          <TabsSubject data={data} refs={refs} />
        </div>

        <div className="flex flex-col justify-center items-center gap-5 w-full lg:gap-6">
          <CategoryButton currentCategoryMain={currentCategoryMain} />
        </div>
      </div>
    </main>
  );
}
