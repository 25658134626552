// grid 스타일을 저장하는 상수 파일
export const GRID_COLS: { [key: number]: string } = {
  3: "grid-cols-3",
  4: "grid-cols-4",
  5: "grid-cols-5",
  6: "grid-cols-6"
};

export const GRID_ROWS: { [key: number]: string } = {
  2: "grid-rows-2",
  3: "grid-rows-3",
  4: "grid-rows-4",
  5: "grid-rows-5",
  6: "grid-rows-6",
  7: "grid-rows-7"
};

export const GRID_MAX_WIDTH: { [key: number]: string } = {
  3: "max-w-[216px] lg:max-w-[432px]",
  4: "max-w-[288px] lg:max-w-[576px]",
  5: "max-w-[360px] lg:max-w-[720px]",
  6: "max-w-[432px] lg:max-w-[864px]"
};
