import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export default function Footer() {
  return (
    <footer className="flex justify-center w-screen bg-gray10 mt-[120px] responsive-padding py-10 lg:px-7">
      <div className="flex flex-col gap-6 responsive-max-width">
        <div className="flex flex-col justify-between items-start gap-4">
          <ReactSVG
            src={`${process.env.PUBLIC_URL}/images/logo/footer_logo.svg`}
          />
          <Link
            className="font-bold"
            to="https://www.ihab.co.kr/"
            target="_blank"
          >
            회사소개
          </Link>
          <p className="font-bold">이용약관</p>
          <p className="font-bold">개인정보처리방침</p>
        </div>
        <ul className="flex flex-col gap-2 text-xs">
          <li className="flex justify-start items-center gap-2">
            <p className="w-14 flex justify-between">
              <span>대</span>
              <span>표</span>
            </p>
            <p className="text-gray40">&#124;</p>
            <p className="w-full">한지혜</p>
          </li>
          <li className="flex justify-start items-center gap-2">
            <p className="w-14 flex justify-between">
              <span>주</span>
              <span>소</span>
            </p>
            <p className="text-gray40">&#124;</p>
            <p className="w-full">강원도 춘천시 시청길 32 해찬빌딩 3층 302호</p>
          </li>
          <li className="flex justify-start items-center gap-2">
            <p className="w-14 flex justify-between">
              <span>고</span>
              <span>객</span>
              <span>센</span>
              <span>터</span>
            </p>
            <p className="text-gray40">&#124;</p>
            <p className="w-full">
              support@ihab.co.kr &nbsp;&#124;&nbsp; 031-380-3724
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
}
