import { ReactSVG } from "react-svg";
import ButtonsMainOutlined from "./common/buttons/ButtonsMainOutlined";
import ButtonsMainFilled1 from "./common/buttons/ButtonsMainFilled1";
import ParticleAnimation from "./animation/ParticleAnimation";

type TestResultProps = {
  category: string;
  isCompleted: boolean;
  isViewSentence: boolean;
  responseCounts: { correct: number; incorrect: number };
  handleReset: () => void;
  navigateToNext: (pathname: string) => void;
  state: { items: any[]; currentIndex: number };
  progressTotalCount?: number;
};

export default function TestResult({
  category,
  isCompleted,
  isViewSentence,
  responseCounts,
  handleReset,
  navigateToNext,
  state,
  progressTotalCount
}: TestResultProps) {
  return (
    <>
      {isCompleted && !isViewSentence && (
        <>
          <ParticleAnimation />
          <div className="flex flex-col justify-center items-center gap-4 w-full md:max-w-[600px] lg:gap-6">
            <ReactSVG
              className="size-40 lg:size-72"
              src={`${process.env.PUBLIC_URL}/images/icons_update/ico_trophy.svg`}
            />
            <div className="break-keep text-xl font-bold text-center lg:text-[32px]">
              <p className="text-mainColor1 mb-4 lg:mb-8">짝짝짝!</p>
              <p className="lg:leading-10">모든 문장을 다 들었습니다.</p>
              <p className="lg:leading-10">이제 다음 듣기를 해 볼까요?</p>
            </div>
            <div className="w-full py-4 px-5 bg-gray20 rounded-[20px]">
              {category === "LISTEN_IN_NOISE" && (
                <ul className="font-bold text-xl lg:text-2xl">
                  <li className="flex justify-center items-center gap-5">
                    <ReactSVG
                      className="size-10"
                      src={`${process.env.PUBLIC_URL}/images/icons_update/ico_speaker.svg`}
                    />
                    <p>잘들린 문장</p>
                    <div className="w-px h-6 bg-gray50">
                      <p className="sr-only">구분선</p>
                    </div>
                    <p className="text-mainColor1">
                      {responseCounts.correct}개
                    </p>
                  </li>
                  <li className="flex justify-center items-center gap-5">
                    <ReactSVG
                      className="size-10"
                      src={`${process.env.PUBLIC_URL}/images/icons_update/ico_speaker_off.svg`}
                    />
                    <p>안들린 문장</p>
                    <div className="w-px h-6 bg-gray50">
                      <p className="sr-only">구분선</p>
                    </div>
                    <p className="text-mainColor1">
                      {responseCounts.incorrect}개
                    </p>
                  </li>
                </ul>
              )}
              {(category === "SHORT_STORY" || category === "LONG_STORY") && (
                <div className="flex justify-center items-center gap-5">
                  <ReactSVG
                    className="size-10"
                    src={`${process.env.PUBLIC_URL}/images/icons_update/ico_speaker.svg`}
                  />
                  <p className="text-xl font-bold lg:text-2xl">
                    퀴즈 {progressTotalCount}개 중{" "}
                    <span className="text-mainColor1">
                      {responseCounts.correct}개
                    </span>{" "}
                    정답
                  </p>
                </div>
              )}
            </div>

            <div className="flex gap-3 w-full">
              <ButtonsMainOutlined
                className="p-3 text-base max-sm:text-sm lg:text-2xl"
                type="button"
                mode="enable/icon"
                text="한 번 더 듣기"
                iconName="ico_refresh"
                iconStyle="size-6 fill-mainColor1"
                onClick={handleReset}
              />
              <ButtonsMainFilled1
                className="p-3 text-base max-sm:text-sm lg:text-2xl"
                type="button"
                mode="enable/icon"
                text={
                  state.items[state.currentIndex + 1] ? "다음 듣기" : "완료"
                }
                iconName="ico_play"
                iconStyle="size-6 fill-white"
                onClick={() => navigateToNext(location.pathname)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
