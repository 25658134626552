import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";

import { AnswerUpdateData, ContextType } from "@libs/common.types";
import {
  GRID_COLS,
  GRID_MAX_WIDTH,
  GRID_ROWS
} from "@constants/crosswordStyles";
import { getCellBorderStyle, getCornerStyle } from "@helpers/crosswordUtils";

type CrosswordGridProps = {
  col: number;
  row: number;
  existCells: number[];
  vertical: number[][];
  horizontal: number[][];
  selectedContext: ContextType[] | null;
  onCellClick: (number: number, direction: "horizontal" | "vertical") => void;
  onContextApplied: () => void;
  onAnswersUpdate: ({ usedIds, answers }: AnswerUpdateData) => void;
};

const CrosswordGrid = forwardRef(function CrosswordGrid(
  {
    col,
    row,
    existCells,
    vertical,
    horizontal,
    selectedContext,
    onCellClick,
    onContextApplied,
    onAnswersUpdate
  }: CrosswordGridProps,
  ref: React.ForwardedRef<any>
) {
  // 현재 퀴즈 번호
  const { quiz } = useParams();
  // 현재 선택된 방향(가로/세로)을 담는 상태
  const [direction, setDirection] = useState<"horizontal" | "vertical" | null>(
    null
  );
  // 하이라이트된 셀을 담는 상태
  const [highlightedCells, setHighlightedCells] = useState<number[]>([]);
  // 마지막 클릭한 셀
  const [lastClickedCell, setLastClickedCell] = useState<number | null>(null);
  // 셀별 토글 상태
  const [toggleState, setToggleState] = useState<{
    [key: number]: "horizontal" | "vertical";
  }>({});
  // 테두리가 적용될 셀 상태
  const [borderedCell, setBorderedCell] = useState<number | null>(null);
  // context를 저장하는 상태
  const [answers, setAnswers] = useState<{
    [key: number]: ContextType | undefined;
  }>({});
  // 입력 순서를 저장하는 상태
  const [inputOrder, setInputOrder] = useState<number[]>([]);

  // 문제 번호를 담는 Map 생성 (cellIndex를 키로 사용)
  const verticalMap = new Map(vertical.map(([cell, number]) => [cell, number]));
  const horizontalMap = new Map(
    horizontal.map(([cell, number]) => [cell, number])
  );

  // 연속된 활성 셀 계산 함수
  const getContinuousCells = (cells: number[], referenceIndex: number) => {
    const result: number[] = [];

    // 왼쪽 또는 위쪽 방향 탐색
    for (let i = referenceIndex - 1; i >= 0; i--) {
      if (existCells.includes(cells[i])) {
        result.push(cells[i]);
      } else {
        break; // 비활성 셀을 만나면 중단
      }
    }

    // 오른쪽 또는 아래쪽 방향 탐색
    for (let i = referenceIndex; i < cells.length; i++) {
      if (existCells.includes(cells[i])) {
        result.push(cells[i]);
      } else {
        break; // 비활성 셀을 만나면 중단
      }
    }

    return result;
  };

  /**
   * 주어진 방향과 셀 인덱스를 기반으로 가로세로퀴즈 그리드에서 하이라이트 셀 계산
   *
   * @param direction - 하이라이트된 셀의 방향, "horizontal" 또는 "vertical".
   * @param cellIndex - 하이라이트를 시작할 셀의 인덱스.
   * @returns 하이라이트된 셀 인덱스 배열.
   */
  const computeHighlightedCells = (
    direction: "horizontal" | "vertical",
    cellIndex: number
  ) => {
    if (direction === "vertical") {
      const colStart = ((cellIndex - 1) % col) + 1;
      const colCells = Array.from(
        { length: row },
        (_, i) => colStart + i * col
      );
      const referenceIndex = colCells.indexOf(cellIndex);
      return getContinuousCells(colCells, referenceIndex);
    } else if (direction === "horizontal") {
      const rowStart = Math.floor((cellIndex - 1) / col) * col + 1;
      const rowCells = Array.from({ length: col }, (_, i) => rowStart + i);
      const referenceIndex = rowCells.indexOf(cellIndex);
      return getContinuousCells(rowCells, referenceIndex);
    }
    return [];
  };

  /**
   * 가로세로퀴즈 그리드의 셀 클릭 이벤트 처리
   *
   * @param cellIndex - 클릭된 셀의 인덱스
   *
   * 이 함수는 클릭된 셀이 가로 또는 세로 방향인지 확인합니다.
   * 셀이 둘 다의 일부인 경우, 마지막으로 클릭된 셀과 현재 토글 상태를 기반으로 방향을 전환합니다.
   * 그런 다음 방향을 설정하고 적절한 단어 인덱스와 방향으로 `onCellClick` 콜백을 호출합니다.
   * 마지막으로 클릭된 셀을 업데이트하고 하이라이트된 셀을 다시 계산합니다.
   * 클릭된 셀이 이미 답을 가지고 있는 경우, 함수는 하이라이트된 셀 중 다음 빈 셀을 테두리 셀로 설정합니다.
   * 그렇지 않으면 클릭된 셀을 테두리 셀로 설정합니다.
   */
  const handleCellClick = (cellIndex: number) => {
    const isHorizontal = horizontalMap.has(cellIndex);
    const isVertical = verticalMap.has(cellIndex);

    if (!isHorizontal && !isVertical) return;

    let newDirection: "horizontal" | "vertical";
    if (isVertical && isHorizontal) {
      if (lastClickedCell === cellIndex) {
        newDirection =
          toggleState[cellIndex] === "vertical" ? "horizontal" : "vertical";
        setToggleState((prev) => ({ ...prev, [cellIndex]: newDirection }));
      } else {
        newDirection = "vertical";
        setToggleState((prev) => ({ ...prev, [cellIndex]: "vertical" }));
      }
    } else if (isVertical) {
      newDirection = "vertical";
    } else {
      newDirection = "horizontal";
    }

    setDirection(newDirection);
    onCellClick(
      newDirection === "vertical"
        ? (verticalMap.get(cellIndex) as number)
        : (horizontalMap.get(cellIndex) as number),
      newDirection
    );

    setLastClickedCell(cellIndex);

    // 새로 계산된 하이라이트 셀 배열
    const computedHighlighted = computeHighlightedCells(
      newDirection,
      cellIndex
    );

    // 클릭한 셀에 응답이 있다면, 하이라이트 셀 중 비어있는 첫 번째 셀을 borderedCell로 설정
    if (answers[cellIndex]) {
      const nextEmpty = computedHighlighted.find((cell) => !answers[cell]);
      if (nextEmpty !== undefined) {
        setBorderedCell(nextEmpty);
      } else {
        setBorderedCell(cellIndex);
      }
    } else {
      setBorderedCell(cellIndex);
    }
  };

  // 방향에 따라 하이라이트 셀 계산
  useEffect(() => {
    if (direction && lastClickedCell !== null) {
      if (direction === "vertical") {
        const colStart = ((lastClickedCell - 1) % col) + 1;
        const colCells = Array.from(
          { length: row },
          (_, i) => colStart + i * col
        );
        const referenceIndex = colCells.indexOf(lastClickedCell);
        setHighlightedCells(getContinuousCells(colCells, referenceIndex));
      } else if (direction === "horizontal") {
        const rowStart = Math.floor((lastClickedCell - 1) / col) * col + 1;
        const rowCells = Array.from({ length: col }, (_, i) => rowStart + i);
        const referenceIndex = rowCells.indexOf(lastClickedCell);
        setHighlightedCells(getContinuousCells(rowCells, referenceIndex));
      }
    }
  }, [direction, lastClickedCell]);

  /**
   * inputOrder 업데이트 헬퍼 함수
   * 셀의 입력 순서를 업데이트합니다.
   *
   * @param {number} cell - 업데이트할 셀의 번호
   * @param {"add" | "remove"} action - 수행할 작업 ("add" 또는 "remove")
   *   - "add": 셀을 입력 순서에 추가합니다. 이미 포함되어 있으면 그대로 반환합니다.
   *   - "remove": 셀을 입력 순서에서 제거합니다.
   * @returns {void}
   */
  const updateInputOrder = (cell: number, action: "add" | "remove") => {
    setInputOrder((prevOrder) => {
      if (action === "add") {
        // 이미 포함되어 있으면 그대로 반환
        if (prevOrder.includes(cell)) return prevOrder;
        return [...prevOrder, cell];
      } else if (action === "remove") {
        return prevOrder.filter((c) => c !== cell);
      }
      return prevOrder;
    });
  };

  // 선택된 문자를 현재 하이라이트된 셀에 적용(또는 이미 입력된 경우 제거)하는 로직
  useEffect(() => {
    if (selectedContext && highlightedCells.length > 0) {
      const updatedAnswers = { ...answers };
      const selectedItem = selectedContext[0];

      // 전체 그리드에서 동일한 context.id가 사용 중인지 확인
      const duplicateKey = Object.keys(updatedAnswers).find(
        (key) => updatedAnswers[Number(key)]?.id === selectedItem.id
      );

      if (duplicateKey !== undefined) {
        // 이미 사용 중인 경우, 해당 셀의 context를 제거
        delete updatedAnswers[Number(duplicateKey)];
        updateInputOrder(Number(duplicateKey), "remove");
        console.log(
          `${duplicateKey}번 셀에서 문자 "${selectedItem.letter}" 제거`
        );
      } else {
        // 중복이 없으면 현재 하이라이트된 영역에서 첫 번째 빈 셀에 새 context를 적용
        for (let cell of highlightedCells) {
          if (!updatedAnswers[cell]) {
            updatedAnswers[cell] = selectedItem;
            updateInputOrder(cell, "add");
            console.log(`${cell}번 셀에 문자 "${selectedItem.letter}" 입력`);
            break;
          }
        }
      }
      setAnswers(updatedAnswers);

      // 사용된 context id 목록을 업데이트
      const usedIds = Object.values(updatedAnswers)
        .filter((answer): answer is ContextType => answer !== undefined)
        .map((answer) => answer.id);
      onAnswersUpdate({ usedIds, answers: updatedAnswers });

      // 다음 입력할 셀로 borderedCell 설정 (빈 셀이 있다면)
      const nextCellIndex = highlightedCells.find(
        (cell) => !updatedAnswers[cell]
      );
      if (nextCellIndex !== undefined) {
        setBorderedCell(nextCellIndex);
      }

      onContextApplied();
    }
  }, [selectedContext, highlightedCells]);

  /**
   * 사용자가 마지막으로 입력한 셀의 문자를 제거하고, 상태를 업데이트하는 함수
   *
   * @remarks
   * - 입력 순서 배열(inputOrder)이 비어있으면 아무 작업도 수행하지 않습니다.
   * - 마지막으로 입력된 셀의 문자를 제거하고, 해당 셀을 입력 순서 배열에서 제거합니다.
   * - 업데이트된 답안 상태를 부모 컴포넌트에 전달합니다.
   *
   * @returns {void}
   */
  const handleUndo = (): void => {
    if (inputOrder.length === 0) return;
    const lastCell = inputOrder[inputOrder.length - 1];
    const updatedAnswers = { ...answers };
    const removed = updatedAnswers[lastCell];
    if (removed) {
      delete updatedAnswers[lastCell];
      console.log(`Undo: ${lastCell}에서 문자 "${removed.letter}" 제거`);
      updateInputOrder(lastCell, "remove");
    }

    setAnswers(updatedAnswers);
    const usedIds = Object.values(updatedAnswers)
      .filter((ans): ans is ContextType => ans !== undefined)
      .map((ans) => ans.id);
    onAnswersUpdate({ usedIds, answers: updatedAnswers });

    // 하이라이트 영역에서 빈 셀을 찾아 borderedCell로 업데이트
    const nextEmpty = highlightedCells.find((cell) => !updatedAnswers[cell]);
    if (nextEmpty !== undefined) {
      setBorderedCell(nextEmpty);
    } else {
      // 만약 모든 셀이 채워졌다면, 최근에 되돌린 셀(lastCell)을 그대로 사용
      setBorderedCell(lastCell);
    }
  };

  // 부모에서 호출할 수 있도록 handleReset을 ref에 노출
  useImperativeHandle(ref, () => ({
    resetGrid: () => {
      setAnswers({});
      setHighlightedCells([]);
      setLastClickedCell(null);
      setToggleState({});
      setBorderedCell(null);
      setInputOrder([]);
    },
    handleUndo
  }));

  // 문제 변경 시 상태 초기화
  useEffect(() => {
    // useImperativeHandle로 노출한 resetGrid 함수를 호출하여 초기화
    if (ref && typeof ref === "object" && ref.current) {
      ref.current.resetGrid();
    }
  }, [quiz]);

  return (
    <div
      className={`w-full grid ${GRID_COLS[col]} ${GRID_ROWS[row]} ${GRID_MAX_WIDTH[col]} border border-solid border-gray50 rounded-xl overflow-hidden lg:h-auto`}
    >
      {Array.from({ length: col * row }).map((_, index) => {
        const cellIndex = index + 1; // 셀 번호 (1부터 시작)
        const isExistCell = existCells.includes(cellIndex); // 존재하는 셀 여부
        const isHighlighted = highlightedCells.includes(cellIndex); // 하이라이트 여부
        const isVertical = verticalMap.has(cellIndex); // 세로 문제 여부
        const isHorizontal = horizontalMap.has(cellIndex); // 가로 문제 여부

        const cellBorderStyle = getCellBorderStyle(cellIndex, col, row); // 기본 테두리 스타일
        const cornerStyle = getCornerStyle(cellIndex, col, row); // 모서리 스타일

        return (
          <div
            key={cellIndex}
            className={`relative flex justify-center items-center aspect-square size-auto max-w-[72px] max-h-[72px] lg:max-w-36 lg:max-h-36 ${
              isExistCell
                ? isHighlighted
                  ? "bg-subColor2 cursor-pointer"
                  : "bg-white cursor-pointer"
                : "bg-gray30 cursor-not-allowed"
            } ${
              borderedCell === cellIndex
                ? `border-2 border-solid border-mainColor1 ${cornerStyle}`
                : cellBorderStyle
            }`}
            onClick={(e) => {
              if (isExistCell) {
                e.stopPropagation(); // 존재하는 셀 클릭 시 이벤트 버블링 차단
                handleCellClick(cellIndex);
              }
            }}
          >
            {/* 셀 번호 렌더링(데이터 작업용) */}
            {/* <p className="font-bold absolute bottom-0 right-0 text-red-600">
              {isExistCell && cellIndex}
            </p> */}
            <p className="font-bold text-[32px] text-gray80 lg:text-[56px]">
              {answers[cellIndex]?.letter}
            </p>

            {/* 한 셀에 가로/세로 문제 번호가 함께 있는 경우 */}
            {isVertical && isHorizontal && (
              <>
                <span className="absolute top-1 left-1 text-xs font-bold text-mainColor1">
                  {horizontalMap.get(cellIndex)}
                </span>
                <span className="absolute top-1 right-1 text-xs font-bold text-orange">
                  {verticalMap.get(cellIndex)}
                </span>
              </>
            )}

            {/* 단일 문제 번호 처리 */}
            {!isVertical && isHorizontal && (
              <span className="absolute top-1 left-1 text-xs font-bold text-mainColor1">
                {horizontalMap.get(cellIndex)}
              </span>
            )}
            {isVertical && !isHorizontal && (
              <span className="absolute top-1 left-1 text-xs font-bold text-orange">
                {verticalMap.get(cellIndex)}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
});

export default CrosswordGrid;
