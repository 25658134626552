import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { TrainingTypes } from "@constants/trainingType";
import { ITrainingType } from "@libs/common.types";
import { Tables } from "@libs/supabase.types";
const { persistAtom } = recoilPersist({
  key: "crossword",
  storage: localStorage
});

export interface ICrosswordList {
  type: ITrainingType;
  items: Tables<"crossword_log">[];
  lastIndex: number;
  count: number;
  timestamp?: Date;
}

export const CrosswordStateDefault = {
  type: TrainingTypes[4],
  items: [],
  lastIndex: 0,
  count: 0,
  timestamp: new Date()
};

export const CrosswordState = atom<ICrosswordList>({
  key: "crosswordState",
  default: CrosswordStateDefault,
  effects_UNSTABLE: [persistAtom]
});
