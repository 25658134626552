import { RecoilRoot } from "recoil";
import "./App.css";

import MainRoutes from "@components/common/routes/MainRoutes";
import HelmetWrapper from "@components/common/HelmetWrapper";
import { HelmetWrapperProps } from "./types/props.types";

const helmetProps: HelmetWrapperProps = {
  title: "아이해브 말귀",
  description:
    "아이해브 말귀는 보청기 착용자의 일상 속 소통을 지원하기 위해 다양한 청각훈련을 제공하는 차별화된 프로그램입니다.",
  keywords:
    "아이해브, ihab, 말귀, 말귀훈련, 청각훈련, 보청기, 보청기 착용자, 보청기 사용자, 청각사, 청능사, 청각치료사, 청각치료사, 청각치료, 청각치료프로그램, 청각치료훈련",
  url: "https://speechear.ihab.co.kr",
  imgSrc: "https://speechear.ihab.co.kr/ihab_se_logo.jpg"
};

function App() {
  return (
    <>
      {/* <HelmetWrapper {...helmetProps} /> */}
      <RecoilRoot>
        <MainRoutes />
      </RecoilRoot>
    </>
  );
}

export default App;
