import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/mousewheel";
import { Mousewheel } from "swiper/modules";

import { CrosswordState } from "@states/CrosswordState";

import { ROUTES } from "@constants/common.routes";

import crosswordData from "@data/crosswordData.json";

import StarrySky from "@components/tests/crossword/StarrySky";
import RocketAnimation from "@components/animation/RocketAnimation";
import Alert from "@components/common/Alert";

const LevelStats = {
  CLEARED: "cleared",
  CURRENT: "current",
  DISABLE: "disable"
} as const;

type LevelStatus = (typeof LevelStats)[keyof typeof LevelStats];

interface RenderStarsProps {
  level: number;
  currentLevel: number;
  setAlertMessage: (message: string) => void;
}

const RenderStars: React.FC<RenderStarsProps> = ({
  level,
  currentLevel,
  setAlertMessage
}) => {
  const [currentLevelState, setCurrentLevelState] = useState<LevelStatus>(
    LevelStats.DISABLE
  );
  const [iconUrl, setIconUrl] = useState<string>(
    `${process.env.PUBLIC_URL}/images/icons_update/ico_star_disable.svg`
  );

  useEffect(() => {
    if (level < currentLevel + 1) {
      setCurrentLevelState(LevelStats.CLEARED);
      setIconUrl(
        `${process.env.PUBLIC_URL}/images/icons_update/ico_star_clear.svg`
      );
    } else if (level === currentLevel + 1) {
      setCurrentLevelState(LevelStats.CURRENT);
      setIconUrl(
        `${process.env.PUBLIC_URL}/images/icons_update/ico_star_enable.svg`
      );
    } else {
      setCurrentLevelState(LevelStats.DISABLE);
      setIconUrl(
        `${process.env.PUBLIC_URL}/images/icons_update/ico_star_disable.svg`
      );
    }
  }, [level, currentLevel]);

  switch (currentLevelState) {
    case LevelStats.CLEARED:
    case LevelStats.CURRENT:
      return (
        <Link
          className="relative flex justify-center items-center"
          to={`${ROUTES.CROSSWORD}/${level}`}
        >
          <ReactSVG className="size-[124px]" src={iconUrl} />
          <p
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-[32px] 
              ${
                currentLevelState === LevelStats.CLEARED
                  ? "text-white"
                  : "text-black"
              }`}
          >
            {level}
          </p>
        </Link>
      );
    case LevelStats.DISABLE:
      return (
        <div
          className="relative flex justify-center items-center cursor-pointer"
          onClick={() => setAlertMessage("이전 단계를 완료해 주세요.")}
        >
          <ReactSVG className="size-[124px]" src={iconUrl} />
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-[32px] text-gray60">
            {level}
          </p>
        </div>
      );
  }
};

export default function CrosswordMain() {
  const crosswordState = useRecoilValue(CrosswordState);
  // 원본 가로세로퀴즈 데이터를 복사 후 뒤집은 순서를 저장
  const reversedData = useMemo(
    () => [...crosswordData].reverse(),
    [crosswordData]
  );

  // Alert 컴포넌트 메시지 상태
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  return (
    <>
      <StarrySky />
      {alertMessage && (
        <Alert message={alertMessage} onClose={() => setAlertMessage(null)} />
      )}
      <main className="relative flex flex-col justify-center items-center w-full h-dvh responsive-padding">
        <section className="absolute top-0 z-10 flex flex-col justify-start items-center responsive-padding responsive-max-width overflow-hidden mt-14 lg:mt-16">
          <div className="flex justify-center items-center w-full min-h-40 max-w-[452px] max-h-72 mt-4">
            <img
              className="w-full"
              src={`${process.env.PUBLIC_URL}/images/logo/crossword_logo.png`}
              alt="Crossword Logo"
            />
          </div>
        </section>
        <Swiper
          className="w-full"
          direction="vertical"
          slidesPerView={5}
          centeredSlides={true}
          spaceBetween="30"
          initialSlide={crosswordData.length - crosswordState.count}
          modules={[Mousewheel]}
          mousewheel={{ forceToAxis: true, sensitivity: 1 }}
          cssMode={true}
        >
          {/* 진행 불가능한 레벨은 Link가 아니라 div로 렌더링 */}
          {reversedData.map((item) => (
            <SwiperSlide className="w-full h-full" key={item.index}>
              <RenderStars
                level={item.index}
                currentLevel={crosswordState.count}
                setAlertMessage={setAlertMessage}
              />
              {item.index > 1 && (
                <div className="absolute top-1/2 left-1/2 -translate-y-0 -translate-x-1/2 w-1 h-40 bg-[#00000033] -z-10 lg:h-40">
                  <p className="sr-only">단계 구분선</p>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <RocketAnimation />
      </main>
    </>
  );
}
