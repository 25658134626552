import Lottie from "lottie-react";
import particleAnimation from "@animations/particleAnimation.json";

export default function ParticleAnimation() {
  return (
    <div className="w-full max-w-[300px] absolute top-1/5 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:max-w-[600px] lg:top-[20%] lg:-translate-y-1/4">
      <Lottie
        className="w-full"
        animationData={particleAnimation}
        loop={false}
      />
    </div>
  );
}
