import { useEffect, useState } from "react";

type SeekBarProps = {
  audio: HTMLAudioElement | null;
  questionAudio?: HTMLAudioElement | null;
  isPlay: boolean;
  isQuestionPlay?: boolean;
  progressCount: number;
};

export default function SeekBar({
  audio,
  questionAudio,
  isPlay,
  isQuestionPlay,
  progressCount
}: SeekBarProps) {
  const [progress, setProgress] = useState(0); // 오디오 재생 진행도

  // 오디오 재생 진행도 업데이트
  useEffect(() => {
    if (!audio && !questionAudio) return;

    setProgress(0); // 문제 변경 시 진행률 초기화

    // 현재 재생 중인 오디오 찾기
    const activeAudio = isQuestionPlay ? questionAudio : audio;
    if (!activeAudio) return;

    // 오디오 재생 속도에 따라 progress를 설정하는 updateProgress 함수
    const updateProgress = () => {
      const progress = (activeAudio.currentTime / activeAudio.duration) * 100;
      setProgress(progress);
    };

    let animationFrameId: number;
    const animateProgress = () => {
      updateProgress();
      animationFrameId = requestAnimationFrame(animateProgress);
    };

    // 오디오 진행률이 변경될 때마다 업데이트
    activeAudio.addEventListener("timeupdate", updateProgress);

    // 오디오 재생 시 requestAnimationFrame 실행
    if (isPlay || isQuestionPlay) {
      animationFrameId = requestAnimationFrame(animateProgress);
    }

    return () => {
      activeAudio.removeEventListener("timeupdate", updateProgress);
      cancelAnimationFrame(animationFrameId);
    };
  }, [audio, questionAudio, isPlay, isQuestionPlay, progressCount]);

  return (
    <div className="w-full h-1 bg-gray30 rounded-full overflow-hidden relative">
      <div
        className="h-full bg-mainColor1"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
}
