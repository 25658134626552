import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  useWatch
} from "react-hook-form";
import { AuthError } from "@supabase/supabase-js";

import { UserAuthState } from "@states/UserAuthState";
import Utils from "@libs/utils";
import type { AuthInputType } from "@libs/common.types";

import useAuth from "@hooks/useAuth";
import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import TextFieldMain from "@components/common/textfield/TextfieldMain";
import LoadingSpinner from "@components/LoadingSpinner";
import Alert from "@components/common/Alert";
import ButtonsMainOutlined from "@components/common/buttons/ButtonsMainOutlined";

export default function ResetPassword() {
  const location = useLocation();
  const { state } = location;
  const auth = useAuth();
  const navigate = useNavigate();
  const uaState = useRecoilValue(UserAuthState);
  console.log("sessionState", uaState);

  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | undefined | null
  >();
  const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] =
    useState<string | undefined | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // 로딩 상태
  const [alertMessage, setAlertMessage] = useState<string | null>(null); // Alert 메시지 상태
  // 비밀번호 초기화 완료 상태
  const [isResetPasswordDone, setIsResetPasswordDone] =
    useState<boolean>(false);

  const [isTouched, setIsTouched] = useState({
    password: false,
    passwordConfirm: false
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    control
  } = useForm<AuthInputType>();

  const watchedValues = useWatch({ control });
  const password = watchedValues.password;
  const passwordConfirm = watchedValues.passwordConfirm;

  // 비밀번호 유효성 검사
  useEffect(() => {
    if (isTouched.password) {
      setPasswordErrorMessage(
        password && password.length >= 8
          ? null
          : "비밀번호는 8자 이상이여야 합니다."
      );
    }
  }, [password, isTouched]);

  // 비밀번호 확인 유효성 검사
  useEffect(() => {
    if (isTouched.passwordConfirm) {
      setPasswordConfirmErrorMessage(
        passwordConfirm && passwordConfirm === password
          ? null
          : "비밀번호가 일치하지 않습니다."
      );
    }
  }, [password, passwordConfirm, isTouched]);

  const onSubmit: SubmitHandler<AuthInputType> = async (data: any) => {
    console.log("onSubmit", data);
    setPasswordErrorMessage(null);
    setPasswordConfirmErrorMessage(null);

    setIsLoading(true); // 로딩 상태 활성화
    try {
      if (passwordErrorMessage || passwordConfirmErrorMessage) {
        setIsLoading(false);
        return;
      }

      // 에러 메시지가 없을 경우 비밀번호 재설정 완료
      if (!passwordErrorMessage && !passwordConfirmErrorMessage) {
        setIsResetPasswordDone(true);
        setAlertMessage(
          "비밀번호 재설정이 완료되었습니다. 홈페이지로 이동하여 다시 로그인 해주세요."
        );
      }

      // const result = await auth.signUp(data);
      // console.log("onSubmit::result", result);
      // if (result) {
      //   setIsLoading(false); // 로딩 종료 후 Alert 표시
      //   setAlertMessage("인증메일이 발송되었습니다. 확인 후 로그인해주세요.");
      //   navigate("/login"); // navigate 로직은 Alert 컴포넌트에서 처리
      // }
    } catch (error) {
      //   const err = error as AuthError;
      //   console.error("onSubmit::error", err.message);
      //   if (err.message === "Email rate limit exceeded") {
      //     setAlertMessage(
      //       "이메일 인증 요청이 너무 많습니다. 잠시 후 다시 시도해주세요."
      //     );
      //   } else {
      //     setAlertMessage(
      //       "회원가입 오류가 발생하였습니다.\n이메일 또는 비밀번호를 확인하여 주세요."
      //     );
      //   }
      // TODO : more error exception handle
    } finally {
      setIsLoading(false); // 로딩 상태 비활성화
    }
  };

  const onError: SubmitErrorHandler<AuthInputType> = (errors) => {
    console.log("onError", errors);
    if (errors.password) {
      setPasswordErrorMessage(errors.password.message);
      setIsTouched((prev) => ({ ...prev, password: true }));
    }
    if (errors.passwordConfirm) {
      setPasswordConfirmErrorMessage(errors.passwordConfirm.message);
      setIsTouched((prev) => ({ ...prev, passwordConfirm: true }));
    }
  };

  return (
    <>
      {/* Alert 컴포넌트 사용 */}
      {!isSubmitting && alertMessage && (
        <Alert
          message={alertMessage}
          onClose={() => {
            setAlertMessage(null);
            navigate("/");
          }}
        />
      )}
      <main className="flex justify-center items-center w-screen h-dvh bg-gray10 responsive-padding">
        {isSubmitting && <LoadingSpinner />}
        <div className="flex flex-col justify-center items-center responsive-max-width">
          <div className="flex flex-col justify-start items-center gap-3 w-full h-dvh max-w-[600px] py-10 lg:h-auto lg:px-0 lg:justify-center lg:gap-16 lg:mt-0">
            <p className="font-GmarketSansBold font-medium text-4xl text-mainColor1 text-center max-lg:text-[28px] max-lg:leading-8 lg:mt-0">
              아이해브말귀 <br />{" "}
              <span className="text-[#1a1a1a]">비밀번호 재설정</span>
            </p>
            <form
              id="signupForm"
              className="flex flex-col gap-6 w-full lg:gap-9"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex flex-col gap-3 lg:gap-4">
                  <p className="text-lg font-bold">비밀번호</p>
                  <TextFieldMain
                    id="password"
                    type="password"
                    placeholder="비밀번호를 입력해 주세요"
                    iconName="ico_password"
                    errorMessage={passwordErrorMessage}
                    register={register("password", {
                      required: "비밀번호는 필수 입력 항목입니다.",
                      minLength: {
                        value: 8,
                        message: "비밀번호는 8자 이상이여야 합니다."
                      },
                      onChange: () =>
                        setIsTouched((prev) => ({ ...prev, password: true }))
                    })}
                  />
                  {passwordErrorMessage && (
                    <p className="text-red80">{passwordErrorMessage}</p>
                  )}
                </div>
                <div className="flex flex-col gap-3 lg:gap-4">
                  <p className="text-lg font-bold">비밀번호 확인</p>
                  <TextFieldMain
                    id="passwordConfirm"
                    type="password"
                    placeholder="비밀번호를 입력해 주세요"
                    iconName="ico_password"
                    errorMessage={passwordConfirmErrorMessage}
                    register={register("passwordConfirm", {
                      required: "비밀번호 확인은 필수 입력 항목입니다.",
                      onChange: () =>
                        setIsTouched((prev) => ({
                          ...prev,
                          passwordConfirm: true
                        }))
                    })}
                  />
                  {passwordConfirmErrorMessage && (
                    <p className="text-red80">{passwordConfirmErrorMessage}</p>
                  )}
                </div>
              </div>

              {!isSubmitting && (
                <div className="flex flex-col gap-4">
                  <ButtonsMainFilled1
                    className="p-3 text-lg lg:p-5 lg:text-2xl"
                    type="submit"
                    mode="enable"
                    text="비밀번호 재설정"
                  />
                  <ButtonsMainOutlined
                    className="p-3 text-lg text-center bg-white lg:p-5 lg:text-2xl"
                    type="link"
                    to="/"
                    mode="enable"
                    text="홈페이지로 이동"
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </main>
    </>
  );
}
