import { ReactSVG } from "react-svg";

type ButtonsPlayLProps = {
  handlePlay: () => void;
  isLoadingAudio: boolean;
  isPlay: boolean;
};

export default function ButtonsPlayL({
  handlePlay,
  isLoadingAudio,
  isPlay
}: ButtonsPlayLProps) {
  console.log("isLoadingAudio", isLoadingAudio);
  return (
    <button
      disabled={isLoadingAudio}
      className={`flex justify-center items-center size-[84px] rounded-full lg:size-[140px] ${
        isLoadingAudio ? "bg-gray50" : "bg-mainColor1"
      }`}
      onClick={handlePlay}
    >
      <ReactSVG
        className="size-[60px] fill-white lg:size-24"
        src={`${process.env.PUBLIC_URL}/images/icons_update/ico_${
          isPlay ? "pause" : "play_L"
        }.svg`}
      />
    </button>
  );
}
