import { useLocation } from "react-router-dom";

const MainFooter = () => {
  const location = useLocation();
  const isLoginPage = location.pathname.includes("/login");

  return (
    <footer
      className={`w-full flex flex-col text-xs text-center text-white leading-normal font-Roboto ${
        !isLoginPage ? "absolute bottom-10 left-0 z-10" : "static mt-10"
      }`}
    >
      <p>Speech Ear</p>
      <p>ⓒ iHAB, Inc. all rights reserved.</p>
    </footer>
  );
};

export default MainFooter;
