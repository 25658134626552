import { Routes, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Login from "@pages/Login";
import SignUp from "@pages/SignUp";
import Terms from "@pages/Terms";
import Privacy from "@pages/Privacy";

export default function AnimatedRoutes() {
  const location = useLocation();
  const isSignUpPage = location.pathname === "/signup";

  return (
    // <div className="relative w-full h-dvh">
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        classNames={isSignUpPage ? "page" : "page-reverse"}
        timeout={300}
        unmountOnExit
        mountOnEnter
      >
        <div className="absolute top-0 left-0 w-full h-full">
          <Routes location={location}>
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/signup"
              element={<SignUp />}
            />
          </Routes>
        </div>
      </CSSTransition>
    </TransitionGroup>
    // </div>
  );
}
