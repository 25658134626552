import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import ButtonsSubOutlined from "@components/common/buttons/ButtonsSubOutlined";
import { ReactSVG } from "react-svg";

type PopupFillRoundProps = {
  onClose: () => void;
  storySentence: string;
  questionSentence?: string;
  storyAudioSrc: string;
  questionAudioSrc?: string;
};

export default function PopupFillRound({
  onClose,
  storySentence,
  questionSentence,
  storyAudioSrc,
  questionAudioSrc
}: PopupFillRoundProps) {
  const location = useLocation();

  // 이야기 듣기 토글 로직
  const [isPlayStory, setIsPlayStory] = useState(false);
  const [storyAudio, setStoryAudio] = useState<HTMLAudioElement | null>(null);

  const handlePlayStory = () => {
    setIsPlayStory(!isPlayStory);
    setIsPlayQuestion(false);
  };

  useEffect(() => {
    if (storyAudioSrc) {
      const audio = new Audio(storyAudioSrc);
      setStoryAudio(audio);
    }
  }, [storyAudioSrc]);

  useEffect(() => {
    if (storyAudio) {
      if (isPlayStory) {
        storyAudio.play();
      } else {
        storyAudio.pause();
      }

      storyAudio.onended = () => {
        setIsPlayStory(false);
      };

      return () => {
        storyAudio.pause();
      };
    }
  }, [isPlayStory, storyAudio]);

  // 문제 듣기 토글 로직
  const [isPlayQuestion, setIsPlayQuestion] = useState(false);
  const [questionAudio, setQuestionAudio] = useState<HTMLAudioElement | null>(
    null
  );

  const handlePlayQuestion = () => {
    setIsPlayQuestion(!isPlayQuestion);
    setIsPlayStory(false);
  };

  useEffect(() => {
    if (questionAudioSrc) {
      const audio = new Audio(questionAudioSrc);
      setQuestionAudio(audio);
    }
  }, [questionAudioSrc]);

  useEffect(() => {
    if (questionAudio) {
      if (isPlayQuestion) {
        questionAudio.play();
      } else {
        questionAudio.pause();
      }

      questionAudio.onended = () => {
        setIsPlayQuestion(false);
      };

      return () => {
        questionAudio.pause();
      };
    }
  }, [isPlayQuestion, questionAudio]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-5">
      <div className="flex flex-col gap-5 p-6 rounded-[20px] bg-white w-full h-auto max-w-[600px]">
        <p className="text-xl font-bold text-center">문장 보기</p>
        <PopupContent
          subject="이야기"
          handlePlay={handlePlayStory}
          isPlay={isPlayStory}
          sentence={storySentence}
          iconName="ico_speaker"
        />
        {location.pathname.includes("/short_story/") && (
          /** @todo 짧은 이야기 팝업 "문제" 아이콘 수정 */
          <PopupContent
            subject="문제"
            handlePlay={handlePlayQuestion}
            isPlay={isPlayQuestion}
            sentence={questionSentence}
            iconName="ico_question"
          />
        )}
        <ButtonsMainFilled1
          type="button"
          mode="enable"
          text="확인"
          className="p-3 text-base max-sm:text-sm lg:text-xl"
          onClick={onClose}
        />
      </div>
    </div>
  );
}

type PopupContentProps = {
  subject: string;
  handlePlay: () => void;
  isPlay: boolean;
  sentence?: string;
  iconName?: string;
};
function PopupContent({
  subject,
  handlePlay,
  isPlay,
  sentence,
  iconName
}: PopupContentProps) {
  return (
    <div className="flex flex-col justify-start items-start gap-3 py-4 px-5 bg-gray20 rounded-xl break-keep">
      <div className="flex justify-start items-center gap-2 w-full">
        <ReactSVG
          src={`${process.env.PUBLIC_URL}/images/icons_update/${iconName}.svg`}
        />
        <p className="text-xl text-gray80 font-bold flex-1">{subject}</p>
        <ButtonsSubOutlined
          type="button"
          mode="enable/icon"
          text=""
          iconName={!isPlay ? "ico_play" : "ico_pause"}
          iconStyle="size-6 fill-gray70"
          onClick={handlePlay}
          className="h-10 py-2 px-3"
        />
      </div>
      <p className="leading-6">{sentence}</p>
    </div>
  );
}
