import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { ReactSVG } from "react-svg";

import HomeSection from "@components/home/HomeSection";
import DailyTrainingList from "@components/home/DailyTrainingList";
import TopicListeningList from "@components/home/TopicListeningList";
import ListeningByTrainingList from "@components/home/ListeningByTrainingList";

import { ROUTES } from "@constants/common.routes";

import {
  taskCompletionState,
  taskProgressState,
  tasksState
} from "@states/progress";
import { UserAuthState } from "@states/UserAuthState";
import { DailyTrainingState } from "@states/DailyTrainingState";
import Footer from "@components/home/Footer";
import Alert from "@components/common/Alert";

// test fetch crossword log
import useAppData from "@hooks/useAppData";
import { CrosswordState } from "@states/CrosswordState";
// END test fetch crossword log

export default function Home() {
  const authState = useRecoilValue(UserAuthState);
  const { profile } = authState;

  const [tasks, setTasks] = useRecoilState(tasksState);
  const [progress, setProgress] = useRecoilState(taskCompletionState);
  const [taskProgress, setTaskProgress] = useRecoilState(taskProgressState);

  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  // 초기화 함수
  const resetTasks = () => {
    setTasks([]);
    setProgress({});
    setTaskProgress({});
    useResetRecoilState(DailyTrainingState);
  };

  // test fetch crossword log
  const appData = useAppData();
  const crosswordState = useRecoilValue(CrosswordState);
  useEffect(() => {
    appData.getCurrentCrosswordLog();
  }, []);
  // END test fetch crossword log

  return (
    <>
      {alertMessage && (
        <Alert message={alertMessage} onClose={() => setAlertMessage(null)} />
      )}
      <main className="flex justify-center items-center w-full responsive-padding mt-14 lg:mt-16">
        <div className="flex flex-col justify-center items-center responsive-max-width">
          {/* 웰컴 메시지 */}
          <section className="flex flex-col justify-between items-center gap-[10px] w-full py-6 px-5 lg:p-11 bg-splash-gradient rounded-[36px] text-white mt-[22px] mb-8 lg:mt-[40px] lg:mb-12">
            <ReactSVG
              className="size-[92px] lg:size-[120px]"
              src={`${process.env.PUBLIC_URL}/images/icons_update/ico_speaker_main.svg`}
              // onClick={resetTasks}
            />
            <div className="flex flex-col justify-start gap-[10px] break-keep size-full">
              <p className="text-sm lg:text-[22px]">
                {profile && profile.name}님 안녕하세요!
              </p>
              <p className="font-bold leading-[22px] lg:text-[32px] lg:leading-none">
                오늘도 말귀와 함께 더 잘 들리는 하루를 보내볼까요?
              </p>
            </div>
          </section>
          {/* 웰컴 메시지 끝 */}

          {/* 오늘의 듣기 */}
          <HomeSection
            sectionTitle="오늘의 듣기"
            sectionGuideText="오늘의 듣기를 진행해 보세요!"
          >
            <DailyTrainingList />
          </HomeSection>
          {/* 오늘의 듣기 끝 */}

          {/* 가로세로퀴즈 */}
          <HomeSection
            sectionTitle={`가로세로퀴즈: ${crosswordState.count}`}
            sectionGuideText="퀴즈를 풀며 즐겁게 들어봐요"
          >
            <Link
              className="flex justify-between items-center gap-4 w-full h-16 lg:h-[104px] py-3 lg:py-8 px-8 bg-[#F8F8F8] rounded-[100px] lg:rounded-3xl max-w-[600px]"
              to={ROUTES.CROSSWORD}
            >
              <ReactSVG
                className="size-10"
                src={`${process.env.PUBLIC_URL}/images/icons_update/ico_puzzle.svg`}
              />
              <p className="w-full text-left text-xl lg:text-2xl leading-none font-bold text-gray80 whitespace-nowrap">
                퀴즈 풀러 출발!
              </p>
              <ReactSVG
                className="w-4 h-4 fill-gray50 flex-shrink-0"
                src={`${process.env.PUBLIC_URL}/images/icons_update/ico_right.svg`}
              />
            </Link>
          </HomeSection>
          {/* 가로세로퀴즈 끝 */}

          {/* 주제별 듣기 */}
          <HomeSection
            sectionTitle="주제별 듣기"
            sectionGuideText="주제를 선택하여 흥미롭게 들어보세요"
          >
            <TopicListeningList />
          </HomeSection>
          {/* 주제별 듣기 끝 */}

          {/* 훈련별 듣기: 일반 유저가 아닐 경우에만 렌더링 */}
          {profile.role !== "ROLE_USER" && (
            <HomeSection
              sectionTitle="훈련별 듣기"
              sectionGuideText="훈련별 듣기를 진행해 보세요"
            >
              <ListeningByTrainingList />
            </HomeSection>
          )}
          {/* 훈련별 듣기 끝 */}
        </div>
      </main>

      {/* Footer 추가 */}
      <Footer />
    </>
  );
}
