import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Utils from "@libs/utils";
import {
  AnswerUpdateData,
  AudioURLType,
  ContextType,
  CrosswordQuizDataType,
  QuestionsType
} from "@libs/common.types";

import { CrosswordState } from "@states/CrosswordState";

import { ROUTES } from "@constants/common.routes";

import useAppData from "@hooks/useAppData";
import useAudioUrl from "@hooks/useAudioUrl";

import crosswordData from "@data/crosswordData.json";

import CrosswordGrid from "@components/tests/crossword/CrosswordGrid";
import ButtonsMainFilled1 from "@components/common/buttons/ButtonsMainFilled1";
import ButtonsMainOutlined from "@components/common/buttons/ButtonsMainOutlined";
import BottomSheet from "@components/tests/crossword/BottomSheet";
import CrosswordResultModal from "@components/tests/crossword/CrosswordResultModal";
import Alert from "@components/common/Alert";

export default function CrosswordQuiz() {
  // const { getSignedSoundBucket } = useAppData();
  const { fetchAudioUrl, fetchAudioUrls } = useAudioUrl();
  const navigate = useNavigate();

  // 현재 퀴즈 번호
  const { quiz } = useParams();
  // quiz 번호에 해당하는 문제 데이터
  const crosswordQuiz: CrosswordQuizDataType = crosswordData[Number(quiz) - 1];
  // crosswordQuiz가 유효하지 않은 경우 early return 처리
  if (
    isNaN(Number(quiz)) ||
    Number(quiz) < 1 ||
    Number(quiz) > crosswordData.length ||
    !crosswordQuiz
  ) {
    return (
      <Alert
        message="잘못된 접근입니다."
        onClose={() => {
          // Alert 닫힌 후 메인 화면으로 이동
          window.location.href = "/main/training/crossword";
        }}
      />
    );
  }
  // crosswordQuiz가 유효한 경우, 필요한 데이터 추출
  const { col, row, existCells, vertical, horizontal } = crosswordQuiz;
  // context를 저장하는 상태
  const [shuffledContext, setShuffledContext] = useState<ContextType[] | null>(
    null
  );
  // 정답 확인 여부
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  // 셀 클릭 여부 상태
  const [isCellClicked, setIsCellClicked] = useState(false);
  // 클릭한 셀의 문제 정보 (문제 번호와 방향)
  const [clickedHint, setClickedHint] = useState<{
    number: number;
    direction: "horizontal" | "vertical";
  } | null>(null);
  // 로드된 오디오 URL
  const [audioUrls, setAudioUrls] = useState<AudioURLType[]>([]);
  // 선택된 context 상태
  const [selectedContext, setSelectedContext] = useState<ContextType[] | null>(
    null
  );
  // Alert 컴포넌트 메시지 상태
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  // Alert 컴포넌트가 닫힐 때 리다이렉트 여부(비정상적인 접근 방지)
  const [shouldRedirectOnAlertClose, setShouldRedirectOnAlertClose] =
    useState(false);
  // 그리드의 답변 상태
  const [gridAnswers, setGridAnswers] = useState<{
    [key: number]: ContextType | undefined;
  }>({});
  // 그리드에 사용된 context id
  const [usedContextIds, setUsedContextIds] = useState<number[]>([]);
  // 그리드 ref
  const gridRef = useRef<any>(null);

  // context를 셔플하여 저장
  useEffect(() => {
    if (crosswordQuiz) {
      setShuffledContext(Utils.shuffleArray(crosswordQuiz.context));
    }
  }, [quiz]);

  // 셀 클릭 이벤트 핸들러
  const onCellClick = (
    number: number,
    direction: "horizontal" | "vertical"
  ) => {
    setIsCellClicked(true); // 셀 클릭 여부 업데이트
    setClickedHint({ number, direction }); // 문제 번호와 유형 업데이트

    // 이미 로드된 파일인지 확인
    const isLoaded = audioUrls.some((url) => url.index === number);
    if (!isLoaded) {
      // 'files' 데이터를 fetchAudioFiles에 전달
      fetchAudioFiles(Number(quiz), number, crosswordQuiz.questions);
    }
  };

  // 오디오 로드 함수
  const fetchAudioFiles = async (
    quiz: number,
    number: number,
    questions: QuestionsType[]
  ) => {
    // 해당 문제의 파일 데이터 찾기
    const fileData = questions.find(
      (question) =>
        question.key === `${quiz}-${String.fromCharCode(64 + number)}`
    );

    if (!fileData) {
      setAlertMessage("파일 데이터를 찾을 수 없습니다.");
      return;
    }

    // Hint와 Answer 데이터 동적으로 처리
    const fileTypes = [
      "Answer",
      ...fileData.hints.map((_, index) => `Hint${index + 1}`)
    ];

    // Supabase에서 파일 경로 생성 및 로드
    const promises = fileTypes.map(async (type) => {
      const fileName =
        type === "Answer"
          ? fileData.answer
          : fileData.hints[Number(type.slice(4)) - 1];
      const filePath = Utils.generateAudioBucketPath("CROSSWORD", fileName);

      try {
        const _url = await fetchAudioUrl(filePath);
        return {
          index: number,
          path: type,
          url: _url
        };
      } catch (err) {
        console.error(`❌ 오디오 파일 로드 실패: `);
        console.error(err);
        return null;
      }
    });

    // 모든 파일 로드 완료
    const audioData = (await Promise.all(promises)).filter(
      (item) => item !== null
    ) as AudioURLType[];
    setAudioUrls((prev) => [...prev, ...audioData]);
  };

  // context 클릭 이벤트 핸들러
  const handleContextClick = (context: ContextType) => {
    setSelectedContext([context]);
  };

  // 정답 확인 핸들러
  const handleCheckAnswer = () => {
    // 모든 existCells에 응답을 입력했는지 확인
    if (Object.entries(gridAnswers).length === existCells.length) {
      setIsAnswerChecked(true);
    } else {
      setAlertMessage("모든 칸에 답을 입력해주세요.");
    }
  };

  // test fetch crossword log
  const appData = useAppData();
  const crosswordState = useRecoilValue(CrosswordState);
  useEffect(() => {
    appData.getCurrentCrosswordLog();
  }, []);
  // END test fetch crossword log

  // 모든 상태 초기화 함수
  const resetAllStates = () => {
    setIsAnswerChecked(false);
    setIsCellClicked(false);
    setClickedHint(null);
    setAudioUrls([]);
    setSelectedContext(null);
    setAlertMessage(null);
    setGridAnswers({});
    setUsedContextIds([]);
    gridRef.current && gridRef.current.resetGrid();
  };

  // 문제 변경 시 상태 초기화
  useEffect(() => {
    if (Number(quiz) <= crosswordState.lastIndex + 1) {
      resetAllStates();
      window.scrollTo(0, 0);
    } else {
      setAlertMessage("잘못된 접근입니다. 이전 단계를 클리어해주세요.");
      setShouldRedirectOnAlertClose(true);
    }
  }, [quiz, crosswordState.lastIndex]);

  if (crosswordQuiz) {
    if (shouldRedirectOnAlertClose) {
      return (
        <>
          {alertMessage && (
            <Alert
              message={alertMessage}
              onClose={() => {
                setAlertMessage(null);
                navigate(ROUTES.CROSSWORD);
              }}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {alertMessage && (
            <Alert
              message={alertMessage}
              onClose={() => {
                setAlertMessage(null);
                if (shouldRedirectOnAlertClose) {
                  navigate(ROUTES.CROSSWORD);
                }
              }}
            />
          )}
          {isAnswerChecked && (
            <CrosswordResultModal
              col={col}
              row={row}
              existCells={existCells}
              vertical={vertical}
              horizontal={horizontal}
              userAnswers={gridAnswers}
              questions={crosswordQuiz.questions}
              onClose={() => setIsAnswerChecked(false)}
              onRetry={resetAllStates}
            />
          )}
          <main className="flex justify-center items-start w-full responsive-padding mt-14 mb-[216px] lg:mt-16">
            <section className="flex flex-col justify-start items-center responsive-max-width w-full h-full gap-5 mt-5 lg:flex-row lg:justify-center lg:gap-12">
              {/* 가로세로 그리드 */}
              <CrosswordGrid
                ref={gridRef}
                col={col}
                row={row}
                existCells={existCells}
                vertical={vertical}
                horizontal={horizontal}
                onCellClick={onCellClick}
                selectedContext={selectedContext}
                onContextApplied={() => setSelectedContext(null)}
                onAnswersUpdate={(data: AnswerUpdateData) => {
                  setUsedContextIds(data.usedIds);
                  setGridAnswers(data.answers);
                }}
              />

              <div className="flex flex-col justify-center items-center gap-5 w-fit">
                {/* 정답 버튼 렌더링 */}
                <div className="grid grid-cols-4 grid-rows-2 place-items-center gap-2 w-full">
                  {shuffledContext &&
                    shuffledContext.map((item) => {
                      const isUsed = usedContextIds.includes(item.id);

                      return (
                        <button
                          key={item.id}
                          className={`size-[72px] rounded-[20px] p-3 text-2xl font-bold text-subColor1 max-sm:size-[60px] lg:size-28 lg:text-5xl active:scale-90 ${
                            isUsed ? "bg-mainColor2" : "bg-gray20"
                          }`}
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            isCellClicked && handleContextClick(item);
                          }}
                        >
                          {item.letter}
                        </button>
                      );
                    })}
                </div>

                {/* 하단 버튼 렌더링 */}
                <div className="flex gap-3 w-full">
                  <ButtonsMainOutlined
                    className="p-3 max-sm:text-sm"
                    type="button"
                    mode="enable/icon"
                    text="되돌리기"
                    iconName="ico_refresh"
                    iconStyle="size-6 fill-mainColor1"
                    onClick={(e) => {
                      e.stopPropagation();
                      gridRef.current && gridRef.current.handleUndo();
                    }}
                  />
                  <ButtonsMainFilled1
                    className="p-3 max-sm:text-sm"
                    type="button"
                    mode="enable/icon"
                    text="정답확인"
                    iconName="ico_check"
                    iconStyle="size-6 fill-white"
                    onClick={handleCheckAnswer}
                  />
                </div>
              </div>
            </section>

            {/* Bottom Sheet */}
            {isCellClicked && clickedHint && (
              <BottomSheet
                hintNumber={clickedHint.number}
                hintType={clickedHint.direction}
                audioUrls={audioUrls
                  .filter((url) => url.index === clickedHint.number)
                  .reduce((acc, cur) => {
                    acc[cur.path] = cur.url;
                    return acc;
                  }, {} as { [key: string]: string })}
              />
            )}
          </main>
        </>
      );
    }
  } else {
    return <>{/** TODO: crosswordQuiz === undefined || null */}</>;
  }
}
