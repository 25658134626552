export default function StarrySky() {
  return (
    <div className="stars-wrapper">
      <svg
        className="stars"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
      >
        <circle
          className="star"
          cx="46.2%"
          cy="80.99%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="39.23%"
          cy="9.35%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="34.02%"
          cy="40.41%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="82.98%"
          cy="33.86%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="51.37%"
          cy="2.38%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="87.45%"
          cy="69.71%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="48.8%"
          cy="43.93%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="81.19%"
          cy="0.57%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="32.29%"
          cy="4.86%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="49.3%"
          cy="80.57%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="24.34%"
          cy="56.93%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="56.07%"
          cy="33.77%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="76%"
          cy="32.63%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="58.54%"
          cy="13.48%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="66.41%"
          cy="93.44%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="39.8%"
          cy="67.05%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="67.79%"
          cy="56.97%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="9.11%"
          cy="90.35%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="10.91%"
          cy="52.5%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="90.58%"
          cy="65.33%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="99.4%"
          cy="91.98%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="66.06%"
          cy="37.2%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="41.89%"
          cy="73.25%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="65.14%"
          cy="99.16%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="19.33%"
          cy="65.56%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="70.01%"
          cy="41.51%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="87.54%"
          cy="10.91%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="34.47%"
          cy="59.41%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="91.92%"
          cy="25.38%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="93.67%"
          cy="98.4%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="28.79%"
          cy="5.17%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="28.84%"
          cy="47.37%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="6.09%"
          cy="57.63%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="26.33%"
          cy="4.28%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="5.84%"
          cy="10.87%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="79.72%"
          cy="57.92%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="59.69%"
          cy="5.41%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="63.84%"
          cy="27.81%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="79.5%"
          cy="44.5%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="65.14%"
          cy="87.58%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="77.77%"
          cy="70.02%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="71.86%"
          cy="89.54%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="21.49%"
          cy="1.31%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="69.16%"
          cy="36.07%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="33.38%"
          cy="40.7%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="21.24%"
          cy="41.12%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="29.58%"
          cy="38.63%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="6.63%"
          cy="7.31%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="88.08%"
          cy="43.9%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="87.5%"
          cy="9.01%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="2.07%"
          cy="10.34%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="89.29%"
          cy="48.63%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="72.13%"
          cy="3.43%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="47.16%"
          cy="32.02%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="74.83%"
          cy="49.21%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="97.7%"
          cy="5.4%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="82.14%"
          cy="53.96%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="55.78%"
          cy="25.04%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="21.02%"
          cy="1.66%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="99.6%"
          cy="81.52%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="69.22%"
          cy="61.02%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="17.74%"
          cy="69.98%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="15.83%"
          cy="91.9%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="36.34%"
          cy="54.49%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="66.52%"
          cy="52.81%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="33.83%"
          cy="16.39%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="4.75%"
          cy="67.26%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="32.84%"
          cy="61.42%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="59.38%"
          cy="82.66%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="90.48%"
          cy="13.97%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="31.46%"
          cy="37.82%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="73.92%"
          cy="20.33%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="24.04%"
          cy="61.94%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="35.76%"
          cy="1.74%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="50.05%"
          cy="94.71%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="25.4%"
          cy="66.8%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="11.03%"
          cy="83.9%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="94.92%"
          cy="89.24%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="0.63%"
          cy="23.43%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="15.18%"
          cy="36.85%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="36.79%"
          cy="85.77%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="62.68%"
          cy="57.78%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="7.59%"
          cy="61.02%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="21.68%"
          cy="99.04%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="3.96%"
          cy="51.78%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="53.02%"
          cy="59.12%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="93.64%"
          cy="80.31%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="86.01%"
          cy="10.44%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="83.41%"
          cy="63.73%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="26.84%"
          cy="84.32%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="4.88%"
          cy="30.77%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="79.19%"
          cy="40.1%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="23.31%"
          cy="50.74%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="52.31%"
          cy="98.64%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="96.71%"
          cy="53%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="83.3%"
          cy="66.04%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="11.03%"
          cy="14.63%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="5.6%"
          cy="82.4%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="4.26%"
          cy="65.76%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="72.09%"
          cy="72.61%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="19.84%"
          cy="91.39%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="65.38%"
          cy="92.31%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="22.34%"
          cy="63.09%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="94.94%"
          cy="9.17%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="35.67%"
          cy="85.46%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="50.73%"
          cy="5.92%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="3.36%"
          cy="58.97%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="18.89%"
          cy="56.64%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="23.69%"
          cy="33.76%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="93.4%"
          cy="20.13%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="92.29%"
          cy="70.97%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="53.88%"
          cy="40.2%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="10.62%"
          cy="78.23%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="42.4%"
          cy="87.98%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="46.34%"
          cy="45.32%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="76.75%"
          cy="27.73%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="69.79%"
          cy="6.05%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="48.33%"
          cy="73.43%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="6.86%"
          cy="99.46%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="37.84%"
          cy="9.7%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="61.41%"
          cy="3.11%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="40.72%"
          cy="47.72%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="42.04%"
          cy="51.79%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="13.94%"
          cy="32.71%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="17.18%"
          cy="45.3%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="65%"
          cy="56.22%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="56.06%"
          cy="86.38%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="50.23%"
          cy="60.05%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="69.2%"
          cy="88.92%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="3.63%"
          cy="45.38%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="67.84%"
          cy="33.96%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="82.06%"
          cy="60.62%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="96.33%"
          cy="15.42%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="26.02%"
          cy="95.88%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="65.98%"
          cy="36.76%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="82.24%"
          cy="82.69%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="66.36%"
          cy="12.43%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="49.56%"
          cy="68.57%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="49.28%"
          cy="99.14%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="23.34%"
          cy="47.26%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="52.49%"
          cy="86.34%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="75.22%"
          cy="90.38%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="7.64%"
          cy="52.16%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="59.49%"
          cy="57.98%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="9.94%"
          cy="34.24%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="70.83%"
          cy="45.11%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="75.7%"
          cy="6.53%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="4.92%"
          cy="95.2%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="56.34%"
          cy="17.15%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="11.63%"
          cy="43.64%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="30.11%"
          cy="39.29%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="9.92%"
          cy="73.22%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="76.29%"
          cy="99.97%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="29.57%"
          cy="49.55%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="62.01%"
          cy="76.57%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="29.65%"
          cy="95.16%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="29.42%"
          cy="44.73%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="8.21%"
          cy="74.19%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="62.2%"
          cy="98.82%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="4.71%"
          cy="10.86%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="89.66%"
          cy="15.87%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="89.92%"
          cy="33.75%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="46.62%"
          cy="9.64%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="44.96%"
          cy="54.71%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="81%"
          cy="61.66%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="51.33%"
          cy="38.44%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="35.37%"
          cy="7.42%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="15.93%"
          cy="42.43%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="60.78%"
          cy="30.73%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="28.63%"
          cy="63.11%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="61.6%"
          cy="18.25%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="82.56%"
          cy="81.69%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="51.89%"
          cy="98.3%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="85.8%"
          cy="44.5%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="0.7%"
          cy="20.57%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="91.7%"
          cy="29.01%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="91.64%"
          cy="71.87%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="25.19%"
          cy="40.15%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="8.89%"
          cy="68.4%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="68.22%"
          cy="73.87%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="61.55%"
          cy="11.5%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="44.71%"
          cy="87.17%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="3.99%"
          cy="89.89%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="46.69%"
          cy="16.27%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="79.23%"
          cy="9.92%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="95.92%"
          cy="40.44%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="36.18%"
          cy="61.57%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="50.98%"
          cy="25.57%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="92.83%"
          cy="63.66%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="61.7%"
          cy="73.82%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="38.35%"
          cy="73.79%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="51.28%"
          cy="47.02%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="18.68%"
          cy="54.93%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="35.59%"
          cy="7.71%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="67.59%"
          cy="3.52%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="10.44%"
          cy="42.44%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="70.8%"
          cy="42.13%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="50.32%"
          cy="46.92%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="35.65%"
          cy="31.16%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="21.68%"
          cy="4.35%"
          r="0.7"
        ></circle>
      </svg>
      <svg
        className="stars"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
      >
        <circle
          className="star"
          cx="52.89%"
          cy="49.53%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="19.37%"
          cy="31.46%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="40.73%"
          cy="76.76%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="88.58%"
          cy="75.87%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="85.51%"
          cy="23.18%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="38.67%"
          cy="53.18%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="70.26%"
          cy="86.83%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="49.85%"
          cy="49.34%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="32.7%"
          cy="6.36%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="12.1%"
          cy="90.3%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="54.75%"
          cy="33.95%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="25.21%"
          cy="30.85%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="26.28%"
          cy="93.56%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="1.2%"
          cy="66.05%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="61.33%"
          cy="94.46%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="29.83%"
          cy="95.52%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="7.73%"
          cy="98.04%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="46.43%"
          cy="23.97%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="98.24%"
          cy="24.06%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="18.32%"
          cy="16.15%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="0.55%"
          cy="4.45%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="13.48%"
          cy="87.65%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="4.49%"
          cy="43.04%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="30.32%"
          cy="88.99%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="30.06%"
          cy="38%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="30.28%"
          cy="25.01%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="60.53%"
          cy="14.84%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="26.45%"
          cy="2.79%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="93.89%"
          cy="82.99%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="43.66%"
          cy="31.05%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="86.69%"
          cy="22.07%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="10.74%"
          cy="4.5%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="52.14%"
          cy="29.54%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="29.64%"
          cy="48.05%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="97.81%"
          cy="4.11%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="87.73%"
          cy="2.57%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="45.42%"
          cy="20.09%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="60.28%"
          cy="94.59%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="38.7%"
          cy="14.6%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="4.43%"
          cy="28.16%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="26.46%"
          cy="41.34%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="45.77%"
          cy="28.13%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="30.96%"
          cy="68.93%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="38%"
          cy="44.18%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="8%"
          cy="1.06%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="66.56%"
          cy="39.06%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="76.76%"
          cy="68.35%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="83.09%"
          cy="36.09%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="55.61%"
          cy="0.53%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="60%"
          cy="91%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="3.26%"
          cy="85.03%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="7.42%"
          cy="44.44%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="82.28%"
          cy="42.67%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="39.84%"
          cy="45.11%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="18.42%"
          cy="27.34%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="86.65%"
          cy="68.33%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="43.05%"
          cy="63.8%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="27.77%"
          cy="1.62%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="37.39%"
          cy="32.3%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="71.09%"
          cy="78.78%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="53.29%"
          cy="22.11%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="76.58%"
          cy="57.13%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="34.4%"
          cy="1.99%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="41.29%"
          cy="81.07%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="48.32%"
          cy="27.21%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="96.58%"
          cy="9.03%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="24.13%"
          cy="34.83%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="55.93%"
          cy="36.24%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="81.31%"
          cy="85.68%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="35.09%"
          cy="26.03%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="59.79%"
          cy="75.58%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="63.07%"
          cy="41.96%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="62.05%"
          cy="58.91%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="92.04%"
          cy="87.96%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="45.22%"
          cy="52.62%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="19.36%"
          cy="91.93%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="21.74%"
          cy="42.59%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="47.22%"
          cy="1.2%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="27.29%"
          cy="78.03%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="61.18%"
          cy="95.91%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="0.4%"
          cy="62.69%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="11.45%"
          cy="69.67%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="80.27%"
          cy="96.47%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="15.36%"
          cy="73.89%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="79.42%"
          cy="65.9%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="74.89%"
          cy="38.23%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="3.07%"
          cy="36.44%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="52.26%"
          cy="41.11%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="59.9%"
          cy="69.85%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="38.34%"
          cy="58.39%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="56.25%"
          cy="33.41%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="14.4%"
          cy="43.2%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="89.42%"
          cy="32.33%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="69.72%"
          cy="82.14%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="36.15%"
          cy="54.4%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="9.35%"
          cy="39.24%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="94.97%"
          cy="1.98%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="2.29%"
          cy="31.03%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="63.26%"
          cy="41.33%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="46.7%"
          cy="60.37%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="51.45%"
          cy="77.98%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="59.09%"
          cy="33.51%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="32.17%"
          cy="53.2%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="12.35%"
          cy="93.97%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="1.87%"
          cy="70.62%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="40.61%"
          cy="94.95%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="97.38%"
          cy="25.6%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="98.77%"
          cy="1.49%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="64.78%"
          cy="60.75%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="27.79%"
          cy="27%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="85.78%"
          cy="34.88%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="91.61%"
          cy="65.25%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="49.34%"
          cy="78.09%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="59.92%"
          cy="24.03%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="72.25%"
          cy="18.3%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="9.66%"
          cy="8.05%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="37.78%"
          cy="91.41%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="3.68%"
          cy="88.56%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="47.37%"
          cy="28.9%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="18.23%"
          cy="71.34%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="43.07%"
          cy="47.13%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="96.93%"
          cy="99.44%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="3.47%"
          cy="71.07%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="97.95%"
          cy="25.07%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="7.26%"
          cy="87.32%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="87.63%"
          cy="27.6%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="66.97%"
          cy="69.78%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="27.96%"
          cy="41.98%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="88.48%"
          cy="26.47%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="21.46%"
          cy="4.32%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="1.96%"
          cy="70.71%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="33.64%"
          cy="39.29%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="25.78%"
          cy="64.69%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="21.44%"
          cy="69.12%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="1.3%"
          cy="48.81%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="15.9%"
          cy="48.66%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="72.89%"
          cy="80.11%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="96.77%"
          cy="29.15%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="48.36%"
          cy="16.45%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="86.54%"
          cy="29.81%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="2.59%"
          cy="68.24%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="95.59%"
          cy="78.23%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="26.65%"
          cy="35.53%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="26.14%"
          cy="29.44%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="40.95%"
          cy="25.98%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="15.25%"
          cy="54.74%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="78.61%"
          cy="50.26%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="23.65%"
          cy="30.42%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="89.27%"
          cy="66.78%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="79.73%"
          cy="23.35%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="11.09%"
          cy="74.46%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="52.73%"
          cy="10.58%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="38.08%"
          cy="97.31%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="42.53%"
          cy="74.65%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="56.65%"
          cy="10.91%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="10.63%"
          cy="79.12%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="43.56%"
          cy="93.91%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="18.42%"
          cy="4.25%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="79.7%"
          cy="76.88%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="35.21%"
          cy="1.55%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="59.79%"
          cy="68.15%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="75.66%"
          cy="99.48%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="38.1%"
          cy="8.82%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="93.68%"
          cy="55.75%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="41.24%"
          cy="33.9%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="29.77%"
          cy="40.21%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="63.75%"
          cy="19.5%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="45.74%"
          cy="66.72%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="44.02%"
          cy="15.49%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="68.98%"
          cy="92.34%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="88.22%"
          cy="81.68%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="27.79%"
          cy="60.99%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="33.15%"
          cy="63.66%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="16.18%"
          cy="48.1%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="81.09%"
          cy="6.32%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="32.12%"
          cy="9.71%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="75.57%"
          cy="3.9%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="19.73%"
          cy="82.82%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="13.1%"
          cy="80.19%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="52.24%"
          cy="39.38%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="63.63%"
          cy="9.79%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="28.25%"
          cy="15.55%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="92.45%"
          cy="78.39%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="64.87%"
          cy="16.17%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="66.54%"
          cy="30.82%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="57.83%"
          cy="19.34%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="2.58%"
          cy="44.92%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="71.6%"
          cy="65%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="5.86%"
          cy="41.45%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="74.01%"
          cy="97.34%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="0.75%"
          cy="17.15%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="79.78%"
          cy="19.84%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="94.98%"
          cy="99.89%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="37.7%"
          cy="2.79%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="97.76%"
          cy="27.88%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="13.29%"
          cy="60.64%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="83.52%"
          cy="77.82%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="67.22%"
          cy="63.72%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="81.49%"
          cy="46.92%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="25.38%"
          cy="76.7%"
          r="0.5"
        ></circle>
      </svg>
      <svg
        className="stars"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
      >
        <circle
          className="star"
          cx="94.37%"
          cy="18.25%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="39.75%"
          cy="22.58%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="35.67%"
          cy="51.07%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="99.38%"
          cy="73.07%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="8.76%"
          cy="46.57%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="34.39%"
          cy="68.41%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="79.75%"
          cy="89.76%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="88.03%"
          cy="50.97%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="18.85%"
          cy="58.73%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="72.54%"
          cy="4.98%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="20.63%"
          cy="38.45%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="85.05%"
          cy="0.03%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="0.32%"
          cy="45.03%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="65.69%"
          cy="20.54%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="33.76%"
          cy="7.13%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="7.19%"
          cy="67.72%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="58.09%"
          cy="99.02%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="18.55%"
          cy="56.76%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="86.3%"
          cy="52.03%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="72.76%"
          cy="13.82%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="50.14%"
          cy="26.71%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="72.51%"
          cy="74.51%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="31.96%"
          cy="40.04%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="32.01%"
          cy="5.69%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="6.7%"
          cy="23.81%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="29.57%"
          cy="6.06%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="44%"
          cy="12.13%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="37.11%"
          cy="13.95%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="34.64%"
          cy="99.05%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="90.81%"
          cy="9.5%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="16.49%"
          cy="38.11%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="66.67%"
          cy="12.5%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="44.24%"
          cy="40.43%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="40.66%"
          cy="18.03%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="59.3%"
          cy="52.59%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="65.58%"
          cy="3.6%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="35.37%"
          cy="18.66%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="17.59%"
          cy="28.96%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="23.6%"
          cy="22.93%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="76.01%"
          cy="23.7%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="46.88%"
          cy="81.05%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="38.83%"
          cy="3.36%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="32.15%"
          cy="44.53%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="51.52%"
          cy="19.97%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="88.48%"
          cy="1.88%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="7.21%"
          cy="71.13%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="41.36%"
          cy="68.91%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="24.29%"
          cy="48.45%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="71.39%"
          cy="57.87%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="41.25%"
          cy="99.4%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="7.8%"
          cy="70.93%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="47.98%"
          cy="55.53%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="87.82%"
          cy="58.84%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="46.56%"
          cy="79.06%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="46.62%"
          cy="12.4%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="13.77%"
          cy="43.19%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="39.62%"
          cy="3.25%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="60.57%"
          cy="99.71%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="48.7%"
          cy="68.02%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="64.95%"
          cy="44.61%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="0.89%"
          cy="2.61%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="23.59%"
          cy="51.88%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="89.59%"
          cy="15.41%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="73.2%"
          cy="5.91%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="33.27%"
          cy="28.16%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="62.08%"
          cy="3.56%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="38.9%"
          cy="20.48%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="67.42%"
          cy="98.47%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="68.01%"
          cy="23.34%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="10.7%"
          cy="90.37%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="8.82%"
          cy="74.88%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="34.81%"
          cy="55.8%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="64.02%"
          cy="37.85%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="74.64%"
          cy="85.97%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="11.91%"
          cy="25.89%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="43.68%"
          cy="39.81%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="41.74%"
          cy="70.24%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="75.26%"
          cy="91.86%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="82.24%"
          cy="94.03%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="62.4%"
          cy="41.87%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="25.65%"
          cy="27.62%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="98.41%"
          cy="38.45%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="3.75%"
          cy="6.87%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="49.98%"
          cy="82.68%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="64.41%"
          cy="7.37%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="75.57%"
          cy="74.04%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="68.23%"
          cy="92.23%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="29.81%"
          cy="48.86%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="23.05%"
          cy="82.95%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="44.59%"
          cy="61.46%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="42.22%"
          cy="75.6%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="34.69%"
          cy="2%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="17.47%"
          cy="90.49%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="43.55%"
          cy="85.46%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="95.91%"
          cy="13.7%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="35.23%"
          cy="61.53%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="52.41%"
          cy="72.98%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="67.39%"
          cy="69.02%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="15.68%"
          cy="19.55%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="29.84%"
          cy="57.58%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="6.08%"
          cy="55.3%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="93.79%"
          cy="15.39%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="27.24%"
          cy="21.14%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="25.19%"
          cy="37.96%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="68.42%"
          cy="75.69%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="15.87%"
          cy="20.78%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="54.22%"
          cy="76%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="79.9%"
          cy="25.13%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="77.58%"
          cy="80.08%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="95.7%"
          cy="70.02%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="6.66%"
          cy="21.53%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="2.51%"
          cy="89.55%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="48.2%"
          cy="87.85%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="2.63%"
          cy="45.78%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="9.49%"
          cy="91.47%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="16.85%"
          cy="19.35%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="20.26%"
          cy="94.57%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="2.22%"
          cy="47.37%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="32.88%"
          cy="76.4%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="15.44%"
          cy="25.77%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="90.68%"
          cy="23.56%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="99.06%"
          cy="60.49%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="78.27%"
          cy="38.05%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="63.59%"
          cy="98.2%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="73.04%"
          cy="74.4%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="74.68%"
          cy="4.83%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="6.95%"
          cy="99.47%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="53.31%"
          cy="48.74%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="39.84%"
          cy="81.43%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="41.49%"
          cy="20.15%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="5.45%"
          cy="4.86%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="66.16%"
          cy="80.99%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="34.32%"
          cy="27.79%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="60.39%"
          cy="37.67%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="73.69%"
          cy="42.01%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="45.15%"
          cy="91.72%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="0.58%"
          cy="90.78%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="96.35%"
          cy="79.47%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="59.85%"
          cy="25.36%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="39.07%"
          cy="28.24%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="39.23%"
          cy="43.14%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="82.42%"
          cy="51.24%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="87.21%"
          cy="7.64%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="36%"
          cy="39%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="1.53%"
          cy="84.15%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="62.36%"
          cy="0.28%"
          r="1.5"
        ></circle>
        <circle
          className="star"
          cx="13.81%"
          cy="89.98%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="81.16%"
          cy="42.32%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="71.73%"
          cy="7.75%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="73.47%"
          cy="80.79%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="65.87%"
          cy="85.04%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="8.92%"
          cy="19.84%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="40.09%"
          cy="98.14%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="84.5%"
          cy="37.73%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="58.79%"
          cy="45.27%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="88.16%"
          cy="28.08%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="77.01%"
          cy="2.06%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="38.48%"
          cy="40.43%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="12.42%"
          cy="16.41%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="11.91%"
          cy="57.74%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="98.42%"
          cy="12.4%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="77.65%"
          cy="59.82%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="6.13%"
          cy="84.84%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="51.02%"
          cy="96.63%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="13.26%"
          cy="69.69%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="82.73%"
          cy="13.46%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="40.8%"
          cy="14.13%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="4.53%"
          cy="99.58%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="92.69%"
          cy="35.86%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="83.92%"
          cy="66.53%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="4.58%"
          cy="90.76%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="50.04%"
          cy="29.21%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="70.7%"
          cy="36.5%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="33.12%"
          cy="57.63%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="1.45%"
          cy="9.19%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="60.66%"
          cy="39.76%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="77.73%"
          cy="54.63%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="98.64%"
          cy="98.45%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="8.46%"
          cy="63.5%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="35.52%"
          cy="39.49%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="97.13%"
          cy="54.69%"
          r="1.4"
        ></circle>
        <circle
          className="star"
          cx="56.6%"
          cy="58.14%"
          r="0.5"
        ></circle>
        <circle
          className="star"
          cx="12.63%"
          cy="81.7%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="88.18%"
          cy="37%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="65.61%"
          cy="63.02%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="56.03%"
          cy="7.83%"
          r="0.9"
        ></circle>
        <circle
          className="star"
          cx="69.69%"
          cy="16.74%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="81.43%"
          cy="71.85%"
          r="1.1"
        ></circle>
        <circle
          className="star"
          cx="36.62%"
          cy="11.32%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="51.46%"
          cy="81.02%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="83.99%"
          cy="87.84%"
          r="0.7"
        ></circle>
        <circle
          className="star"
          cx="18.15%"
          cy="42.5%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="74.52%"
          cy="15.95%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="71.91%"
          cy="99.29%"
          r="1.3"
        ></circle>
        <circle
          className="star"
          cx="46.39%"
          cy="79.77%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="21.2%"
          cy="56.17%"
          r="0.6"
        ></circle>
        <circle
          className="star"
          cx="23.68%"
          cy="13.57%"
          r="1.2"
        ></circle>
        <circle
          className="star"
          cx="91.87%"
          cy="47.66%"
          r="0.8"
        ></circle>
        <circle
          className="star"
          cx="47.08%"
          cy="84.25%"
          r="1"
        ></circle>
        <circle
          className="star"
          cx="53.3%"
          cy="82.35%"
          r="0.9"
        ></circle>
      </svg>
      <svg
        className="extras"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
      >
        <defs>
          <radialGradient
            id="comet-gradient"
            cx="0"
            cy=".5"
            r="0.5"
          >
            <stop
              offset="0%"
              stopColor="rgba(255,255,255,.8)"
            ></stop>
            <stop
              offset="100%"
              stopColor="rgba(255,255,255,0)"
            ></stop>
          </radialGradient>
        </defs>
        <g transform="rotate(-135)">
          <ellipse
            className="comet comet-a"
            fill="url(#comet-gradient)"
            cx="0"
            cy="0"
            rx="150"
            ry="2"
          ></ellipse>
        </g>
        <g transform="rotate(20)">
          <ellipse
            className="comet comet-b"
            fill="url(#comet-gradient)"
            cx="100%"
            cy="0"
            rx="150"
            ry="2"
          ></ellipse>
        </g>
        <g transform="rotate(300)">
          <ellipse
            className="comet comet-c"
            fill="url(#comet-gradient)"
            cx="40%"
            cy="100%"
            rx="150"
            ry="2"
          ></ellipse>
        </g>
      </svg>
    </div>
  );
}
