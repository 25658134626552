/**
 * 모서리 셀인지 판단하는 함수
 *
 * @param cellIndex 현재 셀의 인덱스
 * @param col 전체 컬럼 수
 * @param row 전체 행 수
 * @returns 모서리 스타일 클래스 (rounded-tl-xl 등) 또는 빈 문자열
 */
export const getCornerStyle = (
  cellIndex: number,
  col: number,
  row: number
): string => {
  const isTopLeftCorner = cellIndex === 1;
  const isTopRightCorner = cellIndex === col;
  const isBottomLeftCorner = cellIndex === col * (row - 1) + 1;
  const isBottomRightCorner = cellIndex === col * row;

  if (isTopLeftCorner) return "rounded-tl-xl";
  if (isTopRightCorner) return "rounded-tr-xl";
  if (isBottomLeftCorner) return "rounded-bl-xl";
  if (isBottomRightCorner) return "rounded-br-xl";

  return "";
};

/**
 * 셀의 기본 테두리 스타일을 결정하는 함수
 *
 * @param cellIndex 현재 셀의 인덱스
 * @param col 전체 컬럼 수
 * @param row 전체 행 수
 * @returns 테두리 스타일 클래스 문자열
 */
export const getCellBorderStyle = (
  cellIndex: number,
  col: number,
  row: number
): string => {
  const hasBottomBorder = Math.ceil(cellIndex / col) !== row;
  const hasRightBorder = cellIndex % col !== 0;

  return `${hasBottomBorder ? "border-b border-solid border-gray50" : ""} 
          ${hasRightBorder ? "border-r border-solid border-gray50" : ""}`;
};
