import Lottie from "lottie-react";
import logoAnimation from "@animations/logoAnimation.json";

export default function LogoAnimation({
  onAnimationEnd
}: {
  onAnimationEnd: () => void;
}) {
  const handleComplete = () => {
    const lottieElement = document.querySelector(
      ".lottie-container"
    ) as HTMLElement;
    if (!lottieElement) return;

    lottieElement.style.transition = "opacity 0.5s ease";
    lottieElement.style.opacity = "0";

    onAnimationEnd(); // 페이드 아웃 완료 후 메인 애니메이션 실행
  };

  return (
    <div className="lottie-container flex size-full justify-center items-center translate-y-0">
      <Lottie
        className="size-[200px] lg:size-[300px]"
        animationData={logoAnimation}
        loop={false}
        autoplay={true}
        onComplete={handleComplete} // Lottie 애니메이션 완료 시 호출
      />
    </div>
  );
}
